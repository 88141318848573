import { ACTIONS, ENDPOINTS } from "./../constants";
import { CALL_API, HTTP_METHODS } from "./../../../../constants";

export const clearError = () => ({
  type: ACTIONS.CLEAR_ERROR,
});

export const clearSuccess = () => ({
  type: ACTIONS.CLEAR_SUCCESS,
});

export const showNameSpaceModal = () => ({
  type: ACTIONS.SHOW_NAME_SPACE_MODAL,
});

export const hideNameSpaceModal = () => ({
  type: ACTIONS.HIDE_NAME_SPACE_MDAOL,
});

export const showSuccess = () => ({
  type: ACTIONS.SHOW_SUCCESS,
});
export const showSuccesChangePhone = () => ({
  type: ACTIONS.CHANGE_PHONE_NUMBER_OTP_SUCCEDED,
});

export const login = (payload, headers) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.LOGIN_INIT,
      success: ACTIONS.LOGIN_SUCCEDED,
      fail: ACTIONS.LOGIN_FAILED,
    },
    endpoint: ENDPOINTS.LOGIN,
    method: HTTP_METHODS.POST,
    headers: headers,
  },
});

/*export const login = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTIONS.LOGIN_INIT });

      const response = await api.post(ENDPOINTS.LOGIN, payload);

      // Assuming the response contains the token
      const token = response.data.access;

      // Store the token in sessionStorage
      sessionStorage.setItem("token", token);

      dispatch({ type: ACTIONS.LOGIN_SUCCEDED, payload: response.data });
    } catch (error) {
      dispatch({ type: ACTIONS.LOGIN_FAILED, payload: error });
    }
  };
};
*/
export const forgotPassword = (payload, headers) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.FORGOT_PASSWORD_INIT,
      success: ACTIONS.FORGOT_PASSWORD_SUCCEDED,
      fail: ACTIONS.FORGOT_PASSWORD_FAILED,
    },
    endpoint: ENDPOINTS.FORGOT_PASSWORD,
    method: HTTP_METHODS.POST,
    headers: headers,
  },
});

export const fetchToken = ({ param }) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_TOKEN_INIT,
      success: ACTIONS.FETCH_TOKEN_SUCCEDED,
      fail: ACTIONS.FETCH_TOKEN_FAILED,
    },
    endpoint: ENDPOINTS.FETCH_TOKEN.replace(":param", param || "default"),
    method: HTTP_METHODS.GET,
  },
});

export const resetPassword = ({ ...payload }) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.RESET_PASSWORD_INIT,
      success: ACTIONS.RESET_PASSWORD_SUCCEDED,
      fail: ACTIONS.RESET_PASSWORD_FAILED,
    },
    endpoint: ENDPOINTS.RESET_PASSWORD.replace(":param"),
    method: HTTP_METHODS.POST,
    auth: true,
  },
});

/*export const logout = () =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.LOGOUT_INIT,
        success: ACTIONS.LOGOUT_SUCCEDED,
        fail: ACTIONS.LOGOUT_FAILED
      },
      endpoint: ENDPOINTS.LOGOUT,
      method: HTTP_METHODS.POST,
      auth: true
    }
  })*/

export const logout = () => ({
  type: ACTIONS.LOGOUT_SUCCEDED,
});

export const changeCurrentCenter = (payload) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.CHANGE_CURRENT_CENTER_INIT,
      success: ACTIONS.CHANGE_CURRENT_CENTER_SUCCEDED,
      fail: ACTIONS.CHANGE_CURRENT_CENTER_FAILED,
    },
    endpoint: ENDPOINTS.CHANGE_CURRENT_CENTER,
    method: HTTP_METHODS.POST,
    auth: true,
  },
});

export const changePhoneNumber = (payload, headers) => {
  console.log("ttttppqsk", payload);

  return {
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.CHANGE_PHONE_NUMBER_INIT,
        success: ACTIONS.CHANGE_PHONE_NUMBER_SUCCEDED,
        fail: ACTIONS.CHANGE_PHONE_NUMBER_FAILED,
      },
      endpoint: ENDPOINTS.CHANGE_PHONE_NUMBER,
      method: HTTP_METHODS.POST,
      headers,
    },
  };
};
export const changePhoneNumberOtp = (payload, headers, user_id) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.CHANGE_PHONE_NUMBER_OTP_INIT,
      success: ACTIONS.CHANGE_PHONE_NUMBER_OTP_SUCCEDED,
      fail: ACTIONS.CHANGE_PHONE_NUMBER_OTP_FAILED,
    },
    endpoint: ENDPOINTS.CHANGE_PHONE_NUMBER_OTP.replace(":param", user_id),
    method: HTTP_METHODS.POST,
    headers,
  },
});

const initialState = {
  token: null,
  user: null,
  error: null,
};

import React, {useMemo} from "react"
import { isEmpty } from "lodash"
import { useIntl } from "react-intl"
import ContentLoader from "react-content-loader"

import DisplayItem from "./DisplayItem"
import {Col} from "react-bootstrap"
import {getAttr, toAbsoluteUrl, toMediaUrl} from "../../../../../helpers"
import useHideAndShowField from "./hooks/useHideAndShowField"


const IMG_TYPE = {
  "img": toAbsoluteUrl("/media/svg/files/jpg.svg"),
  "doc": toAbsoluteUrl("/media/svg/files/doc.svg")
}

const ItemLodaer = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={10}
    viewBox="0 0 100% 10"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width="100%" height="10" />
  </ContentLoader>
)

const Item = ({ field, object }) => {

  const intl = useIntl()

  const { name, type="doc", label, icon=undefined, size = 12 } = field

  const conditionalShowAndHideClassName = useHideAndShowField({ object, ...field })

  const value = useMemo(() => {
    return getAttr(object, name, intl.formatMessage({ id: "GENERAL.EMPTY" }))
    // eslint-disable-next-line
  }, [object])

  return (
    <Col lg={size} className={conditionalShowAndHideClassName}>
      <DisplayItem
        primary={ label }
        icon={icon}
        secondary={ !isEmpty(object) ? <a rel="noopener noreferrer" target="_blank" href={toMediaUrl(value)}><img height="50" src={IMG_TYPE[type]} alt="..."/></a> : <ItemLodaer /> }
      />
    </Col>
  )
}


export default Item

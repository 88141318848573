import { ACTIONS } from "../constants"


const initialState = {
  documentations: [],
  documentation: null,
  isFetching: false,
  success: false,
  error: null
}


export default (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_DOCUMENTATION: {
      return { ...state, success: initialState.success, error: null, isFetching: false}
    }

    case ACTIONS.CREATE_DOCUMENTATION_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.CREATE_DOCUMENTATION_SUCCEDED: {
      return { ...state, DOCUMENTATION:payload ,succes: true, error: null ,isFetching: false}
    }
    case ACTIONS.CREATE_DOCUMENTATION_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }

    default: {
      return state
    }
  }
}

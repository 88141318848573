import { lazy } from "react";
import { combinePathRoutes } from "src/helpers";
import routes from "src/routes";
const calendar = {
  path: "/calendar",
  component: lazy(() => import("../Calendar")),
  exact: true,
};
const path = routes.admin.path;

export default combinePathRoutes(
  { path },
  {
    calendar,
  }
);

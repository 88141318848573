import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'

import { FieldError } from "./FieldError"
import useHideAndShowField from './hooks/useHideAndShowField'

const Checkbox = ({ 
  field, 
  defaultValue,
  form, 
  options, 
  size= 12,
  checkboxSize = 'lg', 
  inputGroupClassName = 'form-group',
  ...props
}) => {
  const formik = useFormikContext()
  const conditionalShowAndHideClassName = useHideAndShowField({ formik, fieldName: field.name, ...props })

  useEffect(() => {
    if (field.value && document.getElementById(field.value + field.name)) {
      document.getElementById(field.value + field.name).checked = true
    }

    if (field.value === defaultValue) {
      const ele = document.getElementsByName(field.name)
      for (let i = 0; i < ele.length; i++) {
        ele[i].checked = false
      }
    }
  }, [field.value, field.name, defaultValue])

  const convertToThePerfectType = (value) => {
    if (!isNaN(value)) {
      return parseInt(value, 10)
    }
    if (value === "false") {
      return false
    }
    if (value === "true") {
      return true
    }
    return value
  }

  const onChange = () => {
    const ele = document.getElementsByName(field.name)
    let hasCheckedValue = true
    for (let i = 0; i < ele.length; i++) {
      const currentValue =  convertToThePerfectType(ele[i].value)
      if (ele[i].checked) {
        hasCheckedValue = false
        form.setFieldValue(field.name, options.length > 1 ? [currentValue, ...(field.value || [])] : currentValue)
      }
    }

    if (hasCheckedValue) {
      form.setFieldValue(field.name, defaultValue)
    }
  }

  return (
    <div       className={ inputGroupClassName + conditionalShowAndHideClassName + ` col-lg-${size}`}    >
      {
        options.map((option) => (
          
          <label htmlFor={option.value + field.name} key={option.value} className={`d-flex justify-content-start custom-label-style checkbox checkbox-${checkboxSize} checkbox-single`}>
            <input
              name={field.name}
              onChange={onChange}
              id={option.value + field.name}
              type='checkbox'
              className={`${field.name}`}
              defaultChecked={field.value === option.value}
              value={option.value}
            />
            <span className='mx-1' />
            {option.label}
          </label>
        ))
      }
      <FieldError fieldName={field.name} />
    </div>
  )
}

export default Checkbox

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React from "react"
import {ControlUIProvider} from "../store"


const FormView = ({ children }) => {

  return (
    <ControlUIProvider>
      { children }
    </ControlUIProvider>
  )
}


export default FormView

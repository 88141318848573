import React from "react";
import { isEqual } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import { injectIntl } from "react-intl";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { getSelectRow, getHandlerTableChange } from "../../../../helpers";
import Pagination from "./Pagination";
import { SearchFilter } from "..";
import {
  ARCHIVED_PARAM,
  NOT_ARCHIVED,
  ARCHIVED,
  IS_ACTIVE,
} from "../../../../constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import SearchFilterSelect from "../forms/search-filter/SearchFilterSelect";
const DataTableDCI = ({
  intl,
  entities,
  columns,
  isFetching,
  totalSize,
  keyField = "id",
  queryParams,
  onQueryParamsChange,
  ids,
  filter,
  hasFilter = true,
  hasArchive = false,
  archivedData = "GENERAL.ARCHIVED",
  setIds,
}) => {
  const applyFilter = (values) => {
    const newQueryParams = { ...queryParams, ...values };
    if (!isEqual(newQueryParams, queryParams)) {
      newQueryParams.pageNumber = 1;
      onQueryParamsChange(newQueryParams);
    }
  };
  const paginationOptions = {
    custom: true,
    totalSize,
    ...queryParams,
  };
  let selectRow = {};
  if (ids && setIds) {
    selectRow = { selectRow: getSelectRow({ entities, ids, setIds }) };
  }
  const onChange = () => {
    if (archivedData === "DCI.FILTER.DEACTIVATE") {
      if (queryParams[IS_ACTIVE]) {
        onQueryParamsChange({ [IS_ACTIVE]: 0 });
      } else {
        onQueryParamsChange({ [IS_ACTIVE]: 1 });
      }
    }
  };
  return (
    <>
      {/* {hasFilter && <SearchFilter applyFilter={applyFilter} onlyNumber={false}/>}
{!hasFilter && <br></br> } */}
      {hasArchive && (
        <div className="text-right pb-2 pt-2">
          <FormControlLabel
            control={
              <Switch
                checked={
                  archivedData === "DCI.FILTER.DEACTIVATE"
                    ? !Boolean(queryParams[IS_ACTIVE])
                    : !Boolean(queryParams[ARCHIVED_PARAM])
                }
                onChange={onChange}
                name=""
              />
            }
            label={intl.formatMessage({ id: archivedData })}
          />
        </div>
      )}
      {filter}
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={isFetching}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                pagination={paginationFactory(paginationOptions)}
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bordered={false}
                remote
                keyField={keyField}
                noDataIndication={() => (
                  <div className="p-4 text-center text-muted font-weight-bolder">
                    {intl.formatMessage({ id: "GENERAL.NO_DATA" })}{" "}
                  </div>
                )}
                data={entities}
                columns={columns}
                onTableChange={getHandlerTableChange(onQueryParamsChange)}
                {...selectRow}
                {...paginationTableProps}
              ></BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};
export default injectIntl(DataTableDCI);

import React from "react"
import { FormattedMessage } from "react-intl"
import AccountInformationForm from "./../form/AccountInformationForm"
import { updateAccountInformation } from "../../store/actions"
import { useDispatch, shallowEqual, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {FormView} from "../../../../../../components/partials"


const AccountInformation = () => {

  const dispatch = useDispatch()

  const onSubmit = (values) => {
    dispatch(updateAccountInformation(values))
  }

  const { account, isLoading, error } = useSelector(
    (state) => ({
      error: state.admin.profile.error,
      account: state.admin.profile.account,
      isLoading: state.admin.profile.isLoading
    }),
    shallowEqual
  )
  const history = useHistory()

  const goBackTo = () => {
    history.goBack()
  }

  return (
    <FormView
      goBackTo={goBackTo}
      title={<FormattedMessage id="USER.ACCOUNT_INFORMATION.TITLE" />}
      isLoading={isLoading}
    >
      { ({ saveRef }) => (<AccountInformationForm 
        error={error} 
        onSubmit={onSubmit} 
        initialValues={account} 
        saveRef={saveRef} 
      />) }
    </FormView>
  )
}

export default AccountInformation

import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import _ from "lodash"
//import ReactHtmlParser from 'react-html-parser'
import { isRLTLang } from "./../../../../../i18n";
const useStyles = makeStyles((theme) => ({
  root: {},
  list: {
    width: "100%",
    backgroundColor: "#fff",
    paddind: "0rem",
  },
  item: {
    backgroundColor: "#fff",
    padding: "0rem",
    height: "1.25rem",
  },
  icon: {
    color: "#3699FF",
    backgroundColor: "transparent",
    border: ".125rem solid #3699FF",
  },
  iconSeparator: {
    marginLeft: "3.5rem",
  },
  textSeparator: {
    marginLeft: "0rem",
  },
  direction: {
    textAlign: isRLTLang() ? "right" : "left",
    margin: "0rem",
  },
  text: {
    paddingBottom: ".4rem",
  },
  primary: {
    fontWeight: 400,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    display: "inline",
    lineHeight: "1.25rem",
    color: "#737373",
    fontSize: ".875rem",
    minWidth: "max-content",
  },
  secondary: {
    fontWeight: 700,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    display: "inline",
    color: "#000000",
    fontSize: ".875rem",
  },
}));
const DisplayItem = ({ primary, secondary, html = false, icon,onClick }) => {
  const classes = useStyles();
  const Icon = icon;
  return (
    <List className={classes.list}>
      {" "}
      {/* <ListItem className={classes.item}>
        {" "} */}
        <ListItemText
          primaryTypographyProps={{ classes: { root: classes.text } }}
          className={classes.direction}
          primary={
            <div className="d-flex justify-content-start align-items-center gaps-6">
              {" "}
              <Typography component="span" className={classes.primary}>
                {" "}
                {primary}
              </Typography>{" "}
              <div 
              className="d-flex justify-content-start align-items-center gaps-6"
              style={{
                 cursor: icon ? 'pointer' : 'default',
               }} onClick={_.isFunction(onClick) ? onClick : undefined}>
              {Icon && <Icon className="text-primary"/>}
              <Typography component="span" className={classes.secondary}>
                {html && (
                  <span dangerouslySetInnerHTML={{ __html: secondary }} />
                  )}
                {!html && secondary}
              </Typography>
              </div>
            </div>
          }
        />
      {/* </ListItem> */}
    </List>
  );
};
export default DisplayItem;

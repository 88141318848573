import { useFormikContext } from "formik"
import React from "react"
import useHideAndShowField from "./hooks/useHideAndShowField"


const CustomSpacer = ({
  inputGroupClassName = "form-group",
  field, // { name, value, onChange, onBlur }
  size = 12,
  ...props
}) => {
  const formik = useFormikContext()
  const conditionalShowAndHideClassName = useHideAndShowField({ formik, fieldName: field.name, ...props })
  return (
    <div className={ inputGroupClassName  + conditionalShowAndHideClassName + ` col-lg-${size}`}>
     
    </div>
  )
}

export default CustomSpacer

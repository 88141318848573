import React, {useEffect} from "react"
import { isEmpty } from "lodash"
import Typography from "@material-ui/core/Typography"
import ItemNotFound from "../extras/ItemNotFound"
import {useControlUISelector} from ".."
import RenderItems from "./render/RenderItems"
import {ControlUIProvider} from "../store"


const DisplayItems = ({ fields = [], title, children, show=true, error, isFetching=false, object }) => {

  const displayUiProps = useControlUISelector()

  useEffect(() => {
    if (!isEmpty(fields) && displayUiProps){
      displayUiProps.setDisplayFields(fields, true)
    }
    // eslint-disable-next-line
  }, [fields])

  useEffect(() => {
    if (displayUiProps){
      displayUiProps.setObject(object)
      displayUiProps.setFetching(isFetching || isEmpty(object))
    }

    // eslint-disable-next-line
  }, [object])

  useEffect(() => {
    if (!isEmpty(error)){
      displayUiProps.setError(error)
    }

    // eslint-disable-next-line
  }, [error])

  if (displayUiProps.isNotAvailableResource) {
    return <ItemNotFound />
  }else {
    return (
      <>
        { title && <Typography className="mt-5">{ title }</Typography> }
        { fields && <RenderItems fields={fields} object={object} show={show} /> }
        {
          React.Children.map(children, (child, i) => (
            <React.Fragment key={i}>{ React.cloneElement(child, { ...child.props, isFetching, object }) }</React.Fragment>
          ))
        }
      </>
    )
  }
}

const DisplayProvider = ({ children, ...props }) => <ControlUIProvider><DisplayItems {...props}>{children}</DisplayItems></ControlUIProvider>

export default React.memo(DisplayProvider)

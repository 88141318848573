import React, { useState } from "react"
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Tooltip from '@mui/material/Tooltip';
import EditOffIcon from '@mui/icons-material/EditOff';
import { IconButton } from '@mui/material';
import { FormattedMessage } from "react-intl";
const SelectionToolColumn = ({ isSelected, onChange,isEditable,rowValue,dispatch,actionType }) => {
  
  return (
    <>
    {isSelected && isEditable ? 
    
      <Tooltip title={<FormattedMessage id="GENERAL.UPDATE"/>}>
        <IconButton onClick={(e)=>{
            e.stopPropagation();
            dispatch({type:actionType,payload:rowValue})
        }}>    
      <ModeEditIcon style={{color:'#DC3545'}}/>
        </IconButton>
      </Tooltip>
  : isSelected && !isEditable ? 
    <IconButton>
      <Tooltip title={<FormattedMessage id="Cette mesure est protégée contre les modifications"/>}>
<EditOffIcon/>
</Tooltip>
</IconButton> :      <></>}
    </>
  )
}

const groupingItemOnSelect = (props) => {

  const { ids, isSelected, setIds, customId,rowIndex } = props
  if (isSelected) {
    setIds({id:customId,rowIndex})
    } 
}





// check official documentations: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Selection&selectedStory=Custom%20Selection%20Column%20Header%20Style&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
export const getSelectRow = (props) => {
  const { entities, ids, setIds,dispatch,actionType } = props
  return {
    mode: "radio",
    selectColumnPosition: 'right',
    clickToSelect: true,
    hideSelectColumn: false,
    hideSelectAll: true,
    onSelect:(rowValue,isSelected,rowIndex,event) => {
      groupingItemOnSelect({ ids,isSelected, setIds, customId: entities[rowIndex]?.id,rowIndex })},
   
    selectionRenderer: ({ checked }) => {
      const isSelected = checked
      return (
        <SelectionToolColumn
          dispatch={dispatch}
          actionType={actionType}
          isEditable={entities[ids.rowIndex]?.isEditable}
          rowValue={ids.id}
          isSelected={isSelected}
        />
      )
    },
  }
}

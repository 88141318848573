import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { useDispatch, useSelector } from "react-redux";
import { getLang } from "./../../../../i18n";
import { FR, AR } from "./../../../../constants";
import arTnLocale from "@fullcalendar/core/locales/ar-tn";
import frLocale from "@fullcalendar/core/locales/fr";
import CustomModal from "../../../../modules/admin/containers/calander/CustomModal";
import SessionForm from "../../../../modules/admin/containers/session/components/form/sessionForm";
import SessionEvent from "../../../../modules/admin/containers/calander/SessionEvent";
import SessionDetails from "../../../../modules/admin/containers/session/components/form/SessionDetails";
import {
  createSESSION,
  editsession,
  fetchSESSION,
  fetchAllSession,
  clearStore,
} from "../../../../modules/admin/containers/session/store/actions";

const LANGUAGES = {
  [FR]: frLocale,
  [AR]: arTnLocale,
};

const Calendar = ({ startDate, endDate, programId, ...props }) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [events, setEvents] = useState([]);
  const { category, sessions, session } = useSelector((state) => ({
    category: state.common.auth.profile,
    sessions: state.admin.session.sessions,
    session: state.admin.session.session,
  }));

  const formatDate = (date) => {
    if (date && date.indexOf("-") > 0) {
      const data = date.replace(/-/g, "");
      const year = data.substr(0, 4);
      const month = data.substr(4, 2);
      const day = data.substr(6, 2);
      return `${day}/${month}/${year}`;
    }
    return date;
  };

  const loadSessions = useCallback(() => {
    dispatch(fetchAllSession());
  }, [dispatch]);

  useEffect(() => {
    loadSessions();
    return () => {
      dispatch(clearStore());
    };
  }, [loadSessions, dispatch]);

  useEffect(() => {
    if (sessions.length !== 0) {
      const event_list = sessions.map((session) => ({
        id: session.id,
        title: session.sessionName,
        start: session.date,
        end: session.date,
        extendedProps: {
          description: session.description,
          sessionData: session,
        },
      }));
      setEvents(event_list);
    }
  }, [sessions]);

  const handleDateClick = (info) => {
    if (moment(info.date).isBetween(startDate, endDate, undefined, "[]")) {
      setSelectedDate(info.dateStr);
      setSelectedSession(null);
      setIsEditMode(false);
      setModalIsOpen(true);
    }
  };

  const handleEventClick = (info) => {
    setSelectedSession(info.event.extendedProps.sessionData);
    setIsEditMode(false);
    setModalIsOpen(true);
    dispatch(fetchSESSION(info.event.id));
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleFormSubmit = (values) => {
    if (selectedSession) {
      dispatch(
        editsession({
          ...values,
          sessionID: selectedSession.id,
          training: selectedSession.programId,
        })
      );
      loadSessions();
      setModalIsOpen(false);
    } else {
      dispatch(
        createSESSION({
          ...values,
          date: formatDate(selectedDate),
          training: programId,
        })
      );
      loadSessions();
      setModalIsOpen(false);
    }
  };

  return (
    <div className="fc fc-ltr fc-unthemed">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        eventContent={(eventInfo) => <SessionEvent event={eventInfo.event} />}
        dateClick={handleDateClick}
        initialView="dayGridMonth"
        validRange={{
          start: startDate,
          end: endDate,
        }}
        events={events}
        locale={LANGUAGES[getLang()]}
        selectable
        eventClick={handleEventClick}
        {...props}
      />

      <CustomModal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
        {isEditMode ? (
          <SessionForm
            onSubmit={handleFormSubmit}
            session={
              selectedSession || {
                date: selectedDate,
              }
            }
          />
        ) : (
          <div>
            <div className="session-details">
              {category.id === 1 && (
                <button
                  size="medium"
                  onClick={handleEditClick}
                  className="card-button"
                >
                  Edit
                </button>
              )}
            </div>
            <SessionDetails session={selectedSession} />{" "}
          </div>
        )}
      </CustomModal>
    </div>
  );
};

export default Calendar;

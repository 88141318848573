import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import FormControlLabel from "@material-ui/core/FormControlLabel"
import BaseSwitch from "@material-ui/core/Switch"
import { FieldError } from "./FieldError"
import useHideAndShowField from './hooks/useHideAndShowField'

const Switch = ({
  options = [],
  field,
  label,
  form,
  initialValue,
  required = false,
  inputGroupClassName = 'form-group',
  ...props
}) => {
  const formik = useFormikContext()
  const conditionalShowAndHideClassName = useHideAndShowField({ formik, fieldName: field.name, ...props })

  const onChange = () => {
    const { value } = options.filter((opt) => opt.value !== field.value)?.pop()
    form.setFieldValue(field.name, value)
  }

  useEffect(() => {
    if (conditionalShowAndHideClassName === " d-none") {
      form.setFieldValue(field.name, initialValue)
    }

    // eslint-disable-next-line
  }, [conditionalShowAndHideClassName])

  return (
    <div className={inputGroupClassName + conditionalShowAndHideClassName}>
      { label && <label>{label} {required && "*"}</label> }
      <FormControlLabel
        control={<BaseSwitch checked={field.value} onChange={onChange} name="" />}
        label={(options.find((opt) => opt.value === field.value)?.label) }
        onChange={onChange}
      />
      <FieldError fieldName={field.name} />
    </div>
  )
}

export default Switch

import React from "react";
import { injectIntl } from "react-intl";
import { DynamicForm } from "../../../../../../components/partials/controls";
import { sessionField } from "./fields/sessionField";
const formatDate = (date) => {
  if (date && date.indexOf("-") > 0) {
    const data = date.replace("-", "").replace("-", "");
    const year = data.substr(0, 4);
    const month = data.substr(4, 2);
    const day = data.substr(6, 2);
    return `${day}/${month}/${year}`;
  }
  return date;
};
const formateData = (data) => {
  if (data) {
    data.date = formatDate(data.date);
    return data;
  }
  return {};
};
const SessionForm = (props) => {
  const { onSubmit, session, error, success, saveRef, intl } = props;

  const handleFormSubmit = (values) => {
    onSubmit({ ...values, date: formatDate(values.date) });
  };

  const fields = sessionField({ intl });

  return (
    <>
          {console.log(formateData(session))}
      <h2>{session.id ? "Edit" : "Create"} Session</h2>
      <DynamicForm
        initialValues={formateData(session)}
        onSubmit={handleFormSubmit}
        error={error}
        clearValuesAfterSubmit={success}
        fields={fields}
      >
        <button className="card-button" ref={saveRef} type="submit">
          Submit
        </button>
      </DynamicForm>
    </>
  );
};

export default injectIntl(SessionForm);

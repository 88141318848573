/*eslint no-template-curly-in-string: "off"*/
import _ from "lodash"
import * as Yup from "yup"

const file = (value) => value instanceof File


const checkNestedPath = (path, separator) => Boolean(path.split(separator).length - 1)

const getParentPath = (path, separator) => path.substring(0, path.lastIndexOf(separator))

const createObjectShape = (object) => Yup.object().shape(object)

const createArrayShape = (object) => Yup.array().of(Yup.object().shape(object))

const createFieldSchema = (fields, separator, validationAttr) => {
  const schema = {}
  const nestedAttr = new Set()
  fields.forEach(field => {
    if (_.has(field, validationAttr)) {
      _.set(schema, field.name, _.get(field, validationAttr))
    }
    if (_.has(field, 'fields')){
      _.set(schema, field.name, Yup.array().of(createFieldSchema(field.fields, separator, validationAttr)))
    }
    if (checkNestedPath(field.name, separator)){
      let path = field.name
      while( !_.isEmpty(path = getParentPath(path, separator)) ){
        nestedAttr.add(path)
      }
    }
  })

  Array.from(nestedAttr)
    .sort((a, b) => (b.split(separator).length - a.split(separator).length))
    .forEach((path) => {
      if (path.endsWith("[]")){
        _.set(schema, path.slice(0, -2), createArrayShape(_.get(schema, path)))
      }else {
        _.set(schema, path, createObjectShape(_.get(schema, path)))
      }
    })

  return createObjectShape(schema)
}

export const createYupSchema = (fields, disableValidation=false, separator=".", validationAttr="validation") => {
  if (disableValidation){
    return Yup.object()
  }
  return createFieldSchema(fields, separator, validationAttr)
}


const equalTo = (ref, message) => Yup.mixed().test({
  name: "equalTo",
  exclusive: false,
  message: message || JSON.stringify({
    id: "VALIDATION.ERROR.STRING.EQUAL",
    defaultMessage: "${path} must be the same as ${reference}",
    values: { path: "${path}", unknown: "${reference}" }
  }),
  params: {
    reference: ref.path,
  },
  test: function(value) {
    return value === this.resolve(ref)
  },
})

const differentThanAndValidPassword = (ref, message) => Yup.mixed().test({
  name: "differentThan",
  exclusive: false,
  message: message || JSON.stringify({
    id: "VALIDATION.ERROR.STRING.DIFFERENT" ,
    defaultMessage: "${path} must be different than ${reference}",
    values: { path: "${path}", unknown: "${reference}" }
  }),
  params: {
    reference: ref.path,
  },
  test: function(value) {
      return value !== this.resolve(ref)
  },
}).test({
  name: "isValidPassword",
  exclusive: false,
  message: message || JSON.stringify({
    id: "ERROR.VALIDATION.PASSWORD",
    defaultMessage: "${path} must be a valid password",
    values: { path: "${path}" }
  }),
  test: function (value) {
    if (value){
      const regx = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/)
      return regx.test(value.toString())
    }
    return true
  }
})

const phone = (message) => Yup.mixed().test({
  name: "phone",
  exclusive: false,
  message: message || JSON.stringify({
    id: "VALIDATION.ERROR.NUMBER.PHONE",
    defaultMessage: "${path} must be a valid number",
    values: { path: "${path}" }
  }),
  test: function (value) {
    if (value){
      const regx = new RegExp(/^(\+?\d{0,3})?\s?-?\s?(\(?\d{2}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)?$/)
      return regx.test(value.toString())
    }
    return true
  }
})

const isValidPassword = (message) => Yup.mixed().test({
  name: "isValidPassword",
  exclusive: false,
  message: message || JSON.stringify({
    id: "ERROR.VALIDATION.PASSWORD",
    defaultMessage: "${path} must be a valid password",
    values: { path: "${path}" }
  }),
  test: function (value) {
    if (value){
      const regx = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/)
      return regx.test(value.toString())
    }
    return true
  }
})

const isfile = (message) => Yup.mixed().test({
  name: "isFile",
  exclusive: false,
  message: message || JSON.stringify({
    id: "VALIDATION.ERROR.MIXED.REQUIRED",
    defaultMessage: "${path} is a required field",
    values: { path: "${path}" }
  }),
  test: function (value) {
    if (_.isString(value)){
      return true
    }
    return file(value)
  }
})


const array = (message) => Yup.mixed().test({
  name: "array",
  exclusive: false,
  message: message || JSON.stringify({
    id: "VALIDATION.ERROR.MIXED.ARRAY",
    defaultMessage: "${path} must be a valid array",
    values: { path: "${path}" }
  }),
  test: function (value) {
    if (value){
      return Array.isArray(value)
    }
    return true
  }
})

const isArabicText = (min,max)=> Yup.string()
  .test({
    name:"isNotNumber",
    exclusive:false,
    message: JSON.stringify({
      id:"VALIDATION.ERROR.MUST.NOT.CONTAIN.NUMBER",
      defaultMessage: "must not contain number",
    }),
    test: function(value){
      const pattern = /^\D+$/;
      return pattern.test(value)
    }

}).test(
  {
    name: "isArabicText",
    exclusive: false,
    message: JSON.stringify({
      id: "VALIDATION.WRITE_IN_ARBIC" ,
      defaultMessage: "must be written in arabic",
    }),
    test: function(value) {
      if (!value) return true;
      const arabicPattern = /^[\u0621-\u0652\040]+$/;
      return arabicPattern.test(value);
    },
  }
).min(min).max(max);
const mustNotContainSpecialCharacters = (min, max) => Yup.string().test({
  name: "mustNotContainSpecialCharacters",
  exclusive: false,
  message: JSON.stringify({
    id: "VALIDATION.MUST.NOT.CONTAIN.SPECIAL.CARACTERS",
    defaultMessage: "this field must not contain special characters",
    values: { path: "${path}" }
  }),
  test: function (value) {
    if (value) {
      const regx = new RegExp(/^[a-zA-Z0-9]+$/)
      console.log(regx.test(value))
      return regx.test(value.toString())
    }
    return true
  }
}).min(min).max(max)


Yup.addMethod(Yup.string, "equalTo", equalTo)
Yup.addMethod(Yup.string,"differentThanAndValidPassword",differentThanAndValidPassword)
Yup.addMethod(Yup.string,"isArabicText",isArabicText)
Yup.addMethod(Yup.string,"isValidPassword",isValidPassword)
Yup.addMethod(Yup.string, "mustNotContainSpecialCharacters", mustNotContainSpecialCharacters)
Yup.addMethod(Yup.number, "phone", phone)
Yup.addMethod(Yup.mixed, "array", array)
Yup.addMethod(Yup.mixed, "isfile", isfile)


import React from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"


const useStyles = makeStyles((theme) => ({
  heading : {
  fontWeight : 600,
  },
  root: {
    width: "100%",
  },
  details: {
    display: "block"
  },
}))

const AccordionMui = withStyles({
  root: {
    borderRadius : ".625rem",
    width: "100%",
    boxShadow: "none !important",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",

    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummaryMui = withStyles({
  root: {
    borderRadius : ".625rem",
    background:"#f6f6f6",
    color:"#1B4883",
    borderBottom: ".0625rem solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    fontWeight : 900,
    "&$expanded": {
      minHeight: 56,
      color:"#FFFFFF",

    },
  },
  content: {
    "&$expanded": {
      margin: ".75rem 0",
   

    },
  },
  expanded: {
    background:"#DC3545",
  },
})(MuiAccordionSummary)



const Accordion = ({ title, children,setIsExpanded,isExpanded }) => {
  const classes = useStyles()
  const onChange = () =>  {
    setIsExpanded(prev=>!prev);
  }
  return (
    <div className={classes.root}>
      <AccordionMui  onChange={
            ()=>onChange()
          } 
          expanded={isExpanded}>
        <AccordionSummaryMui
         
          expandIcon={<ExpandMoreIcon className={classes.heading} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{ title }</Typography>
        </AccordionSummaryMui>
        <AccordionDetails className={classes.details}>
          { children }
        </AccordionDetails>
      </AccordionMui>
    </div>
  )
}


export default Accordion

import React from 'react'
import { memoize } from 'lodash'
import { DEFAULT_ITEM, ITEM_COMPONENT } from './../item-types/item-types'
import { Col, Row } from 'react-bootstrap'
import { getAttr } from '../../../../../helpers'
import useHideAndShowField from './hooks/useHideAndShowField'

const RenderNestedItems = ({ field, object, isFetching }) => {
  const renderField = memoize(({ component, ...props }, item) => {
    const Component = ITEM_COMPONENT[component] || DEFAULT_ITEM
    return (
      <Component field={props} isFetching={isFetching} object={item} />
    )
  })

  const { name, fields } = field
  const conditionalShowAndHideClassName = useHideAndShowField({ object, ...field })

  return (
    <Col className={conditionalShowAndHideClassName}>
      <Row>
        {getAttr(object, name, []).map((item) => fields.map((field, i) => (
          <React.Fragment key={i}>{renderField(field, item)}</React.Fragment>
        )))}
      </Row>
    </Col>
  )
}

export default React.memo(RenderNestedItems)

/* import { lazy } from "react";
import { combinePathRoutes } from "src/helpers";
import routes from "src/routes";

const CalendarPage = {
  path: "/calendar/:param",
  component: lazy(() => import("../CalendarPage")),
  exact: true,
};

const path = routes.admin.path;

export default combinePathRoutes(
  { path },
  {
    CalendarPage,
  }
);
 */
import { lazy } from "react";
import { combinePathRoutes } from "src/helpers";
import routes from "src/routes";

const CalendarPage = {
  path: "/calendar/:programId",
  component: lazy(() => import("../CalendarPage")),
  exact: true,
};

const SessionFormPage = {
  path: "/session-form/:programId",
  component: lazy(() => import("../sessionFormPage")),
  exact: true,
};

const path = routes.admin.path;

export default combinePathRoutes(
  { path },
  {
    CalendarPage,
    SessionFormPage,
  }
);

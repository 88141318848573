export const ACTIVATE = "activate"
export const DEACTIVATE = "deactivate"
export const DELETE = "delete"
export const UNDELETE = "undelete"
export const VIEW = "view"
export const SEARCH = "search"
export const UPDATE = "update"
export const REGISTRED = "registred"
export const CREATE = "create"
export const VIEW_UPDATE = "view_update"
export const CLOSE = "close"
export const LIST = "list"

import React from "react"
import { FormattedMessage } from "react-intl"
import ChangePasswordForm from "./../form/ChangePasswordForm"
import {changeAccountPassword} from "../../store/actions"
import {useDispatch, useSelector, shallowEqual} from "react-redux"
import { useHistory } from "react-router-dom"
import {FormView} from "../../../../../../components/partials"


const ChangePassword = () => {

  const dispatch = useDispatch()
  const onSubmit = (values) => {
    dispatch(changeAccountPassword(values))
  }

  const { isLoading, error } = useSelector(
    (state) => ({
      error: state.admin.profile.error,
      isLoading: state.admin.profile.isLoading
    }),
    shallowEqual
  )
  const history = useHistory()

  const goBackTo = () => {
    history.goBack()
  }

  return (
    <FormView
      goBackTo={goBackTo}
      title={<FormattedMessage id="USER.CHANGE_PASSWORD.TITLE" />}
      isLoading={isLoading}
    >
      { ({ saveRef }) => (<ChangePasswordForm
        error={error}
        onSubmit={onSubmit}
        saveRef={saveRef} />
      ) }
    </FormView>
  )
}

export default ChangePassword

import React from "react"


const withCropper = (BaseComponent, saveCroppedImg) => props =>
  (<BaseComponent
    {...props}
    saveCroppedImg={saveCroppedImg}
  />);

export default withCropper

/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux"

// Reducers
import profileReducer from "./containers/profile/store/reducers"


import dashboardReducer from "./containers/home/store/reducers"
import programReducer from "./containers/program/store/reducers"
import documentReducer from "./containers/document/store/reducers"
import sessionReducer from "./containers/session/store/reducers"

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default combineReducers({
  
  profile: profileReducer,
  dashboard: dashboardReducer,
  program:programReducer,
  documentation:documentReducer,
  session:sessionReducer
})




import { useEffect } from "react"
import "moment/locale/ar-tn";
import "moment/locale/fr";
import { isRLTLang } from "src/i18n";
import moment from "moment";

const useMomentLocale = () => {
    useEffect(() => {
        isRLTLang() ? moment.locale("ar-tn") : moment.locale("fr");
    
        return () => {};
      }, []);
    }
export default useMomentLocale

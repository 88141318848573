import { ACTIONS } from "../constants"


const initialState = { 
  CountFAq:0,
  faq:[],
  CountProducts:0,
  products:[],
  isFetching: false, 
  isLoading: false,
  hasMore: true, 
  error: null
}


export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {
 

    case ACTIONS.FETCH_FAQ_INIT : {
      return { ...state, isLoading: true, structure: null, error: null }
    }
    case ACTIONS.FETCH_FAQ_SUCCEDED : {
      return { ...state, CountFAq: payload.count,faq:payload.results, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_FAQ_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.FETCH_PRODUCTS_INIT : {
      return { ...state, isLoading: true, structure: null, error: null }
    }
    case ACTIONS.FETCH_PRODUCTS_SUCCEDED : {
      return { ...state, CountProducts: payload.count,products:payload.results, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_PRODUCTS_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }


    default: {
      return state
    }
  }
}

import _ from "lodash"
import { INPUT } from "./../../../../../components/partials"
import * as Yup from "yup";


const resetPasswordFields = ({ intl }) => ([
  {
    name: "currentPassword",
    component: INPUT,
    required: true,
    label: intl.formatMessage({ id: "USER.INPUT.CURRENT_PASSWORD" }),
    placeholder: intl.formatMessage({ id: "USER.INPUT.CURRENT_PASSWORD" }),
    inputClassName: "form-control form-control-solid h-auto py-5 px-6",
    inputGroupClassName: "form-group fv-plugins-icon-container",
    type: "password",
    validation: Yup.string().required(),
  },
  {
    name: "newPassword",
    component: INPUT,
    required: true,
    label: intl.formatMessage({ id: "USER.INPUT.NEW_PASSWORD" }),
    placeholder: intl.formatMessage({ id: "USER.INPUT.NEW_PASSWORD" }),
    inputClassName: "form-control form-control-solid h-auto py-5 px-6",
    inputGroupClassName: "form-group fv-plugins-icon-container",
    type: "password",
    validation: Yup.string().differentThanAndValidPassword(Yup.ref("currentPassword"))
      .required(),
  },
  {
    name: "confirmPassword",
    required: true,
    component: INPUT,
    label: intl.formatMessage({ id: "USER.INPUT.CONFIRM_PASSWORD" }),
    placeholder: intl.formatMessage({ id: "USER.INPUT.CONFIRM_PASSWORD" }),
    inputClassName: "form-control form-control-solid h-auto py-5 px-6",
    inputGroupClassName: "form-group fv-plugins-icon-container",
    type: "password",
    validation: Yup.string().equalTo(Yup.ref("newPassword")).required(),
  },
])


export default _.memoize(resetPasswordFields)

import React from 'react'
import Alert from 'react-bootstrap/Alert'
import { FormattedMessage } from 'react-intl'
import _ from "lodash"
//import { ERROR_CODES } from './../../../../constants'

export const FormattedError = ({ error, onClose = f => f,message }) => {
  // const { data: { errorCode } = {}, statusCode } = error


  return (
    <Alert variant='transparent' onClose={onClose} dismissible={_.isFunction(onClose) ? true : false}>
      <div style={{color:'#E02D39',fontSize:"14px"}} className='font-weight-bold'>
        <FormattedMessage id={  message  || 'ERROR.CODE.INTERNAL_ERROR'} />
      </div>
    </Alert>
  )
}

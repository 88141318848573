import { useEffect, useState } from "react"
import {getAttr} from "../../../../../../helpers"
import { isString, isArray, isEmpty } from "lodash"


const useHideAndShowField = ({ formik, fieldName, hide = false, hideOn, condition = false, hideOnFields = [], hideOnValue="" }) => {

  const [show, setShow] = useState(hide)

  useEffect(() => {
    if (isString(hideOn)){
      let field = getAttr(formik.values, hideOn, false)
      let formattedCondition = condition
      if (typeof condition === "boolean") {
        formattedCondition = ( Boolean(condition)).toString()
      }
      if (typeof field === "boolean") {
        field = (Boolean(field)).toString()
      }
      const checkCondition = Boolean(isArray(formattedCondition) ? !formattedCondition.includes(field) : field !== formattedCondition)
      setShow(checkCondition)
    }

    //  eslint-disable-next-line
  }, [formik])


  useEffect(() => {
    if (show) {
      formik.setFieldValue(fieldName, hideOnValue)
      formik.setTouched({},false)
    }

    //  eslint-disable-next-line
  }, [show])


  useEffect(() => {
    if (!isEmpty(hideOnFields) && isArray(hideOnFields)){
      const conditions = []
      hideOnFields.forEach(({ hideOn, condition }) => {
        if (isString(hideOn)){
          let formattedCondition = condition
          if (typeof condition === "boolean") {
            formattedCondition = (Boolean(condition)).toString()
          }
          let field = getAttr(formik.values, hideOn, false)
          if (typeof field === "boolean") {
            field = (Boolean(field)).toString()
          }
          const checkCondition = isArray(formattedCondition) ? !formattedCondition.includes(field) : field !== formattedCondition
          conditions.push(checkCondition)
          
        }
      })
      if (conditions.every((value) => value === false)){
        setShow(false)
      }else{
        setShow(true)
      }
    }


    //  eslint-disable-next-line
  }, [formik, hideOnFields])

  useEffect(() => {
    if (!isEmpty(hideOnFields) && isArray(hideOnFields)){
      hideOnFields.some(({ hideOn, condition }) => {
        if (isString(hideOn)){
          const field = getAttr(formik.values, hideOn, false)
          const checkCondition = Boolean(isArray(condition) ? !condition.includes(field) : field !== condition)
          if (checkCondition){
            setShow(checkCondition)
            return true
          }
        }
        return false
      })
    }

    //  eslint-disable-next-line
  }, [formik])

  return show ? " d-none" : " "
}


export default useHideAndShowField

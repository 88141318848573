/* eslint-disable jsx-a11y/role-supports-aria-props,no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState} from "react"
import { injectIntl } from "react-intl"
//import { isEmpty, isEqual } from "lodash"
import {AR, FR} from "../../../../constants"
import {getLang} from "../../../../i18n"
//import useControlUISelector from "../store/hooks/useControlUISelector"

const LANGUAGES = {
  [FR]: "french-version",
  [AR]: "arabic-version"
}

const LanguageTab = ({ children, intl }) => {

  //const controlsUI = useControlUISelector()
  const [tab, setTab] = useState(LANGUAGES[getLang()])

  //const [prevError, setPrevError] = useState({})

  /*useEffect(() => {
    if (!isEmpty(controlsUI.langError) && !isEqual(controlsUI.langError, prevError)){
      setPrevError(controlsUI.langError)
    }
    // eslint-disable-next-line
  }, [controlsUI.langError])*/

  const onChangeTab = (lang) => {
    setTab(LANGUAGES[lang])
  }

  return (
  <>
    {/* <ul className="nav nav-tabs nav-tabs-line " role="tablist">
      <li className="nav-item" onClick={() => onChangeTab(FR) }>
        <span
          className={`nav-link ${tab === "french-version" && "active"}`}
          data-toggle="tab"
          role="button"
          aria-selected={(tab === "french-version").toString()}
        >
          {intl.formatMessage({ id: "GENERAL.FRENCH_VERSION" })}
        </span>
      </li>
      <li className="nav-item" onClick={() => onChangeTab(AR) }>
        <span
          className={`nav-link ${tab === "arabic-version" && "active"}`}
          data-toggle="tab"
          role="button"
          aria-selected={(tab === "arabic-version").toString()}
        >
          {intl.formatMessage({ id: "GENERAL.ARABIC_VERSION" })}
        </span>
      </li>
    </ul> */}
    {  children({ isFr: tab === LANGUAGES[FR], isAr: tab === LANGUAGES[AR] }) }
  </>
)
}


export default injectIntl(LanguageTab)

export const CREATE_SESSION_INIT = "SESSION@CREATE_SESSION_INIT";
export const CREATE_SESSION_SUCCEDED = "SESSION@CREATE_SESSION_SUCCEDED";
export const CREATE_SESSION_FAILED = "SESSION@CREATE_SESSION_FAILED";
export const CLEAR_SESSION = "SESSION@CLEAR_SESSION";

export const EDIT_SESSION_INIT = "SESSION@EDIT_SESSION_INIT";
export const EDIT_SESSION_SUCCEDED = "SESSION@EDIT_SESSION_SUCCEDED";
export const EDIT_SESSION_FAILED = "SESSION@EDIT_SESSION_FAILED";

export const FETCH_SESSION_INIT = "SESSION@FETCH_SESSION_INIT";
export const FETCH_SESSION_SUCCEDED = "SESSION@FETCH_SESSION_SUCCEDED";
export const FETCH_SESSION_FAILED = "SESSION@FETCH_SESSION_FAILED";

export const FETCH_ALL_SESSIONS_INIT = "SESSION@FETCH_ALL_SESSIONS_INIT";
export const FETCH_ALL_SESSIONS_SUCCEDED =
  "SESSION@FETCH_ALL_SESSIONS_SUCCEDED";
export const FETCH_ALL_SESSIONS_FAILED = "SESSION@FETCH_ALL_SESSION_FAILED";

export const FETCH_ALL_SESSION_INIT = "SESSION@FETCH_ALL_SESSION_INIT";
export const FETCH_ALL_SESSION_SUCCEDED = "SESSION@FETCH_ALL_SESSION_SUCCEDED";
export const FETCH_ALL_SESSION_FAILED = "SESSION@FETCH_ALL_SESSION_FAILED";

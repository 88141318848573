import React from "react"
import { useFormikContext } from "formik"
import { FieldError, useFieldCSSClasses } from "./FieldError"
import useHideAndShowField from "./hooks/useHideAndShowField"

const MetricInput = ({
                   field, // { name, value, onChange, onBlur }
                   form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                   label,
                   label_metric,
                   inputGroupClassName = "form-group",
                   inputClassName = "form-control",
                   type = "text",
                   required = false,
                   disabled = false,
                   maxLength = 256,
                   form,
                   placeholder,
                   ...props
               }) => {

    const formik = useFormikContext()
    const conditionalShowAndHideClassName = useHideAndShowField({ formik, fieldName: field.name, ...props })
    const fieldCSSClasses = useFieldCSSClasses(touched, errors, field.name)

    return (
        <div className={inputGroupClassName + conditionalShowAndHideClassName}>
            {label && <label> {label} {(required && " *")}</label>}
            <div className="input-group">

                <input
                    type={type}
                    disabled={disabled}
                    className={`${inputClassName} ${fieldCSSClasses}`}
                    maxLength={maxLength}
                    autoComplete="off"
                    placeholder={placeholder}
                    {...field}
                    value={field.value || ""}
                />
                <FieldError fieldName={field.name} />
                <div className="input-group-prepend">
                    <span className="input-group-text">{label_metric}</span>
                </div>
            </div>


        </div>
    )
}


export default MetricInput

import { lazy } from "react"

import { combinePathRoutes } from "../../../helpers"
import baseRoutes from "../../../routes"

const Login = lazy(() => import("../containers/Login"))
const Logout = lazy(() => import("../containers/Logout"))


const authenticationRoutes = {
  login : {
    path: "/login",
    component: Login
  },
  logout : {
    path: "/logout",
    component: Logout
  }
}


// Combined routes
export default combinePathRoutes({ path: baseRoutes.auth.path }, authenticationRoutes)

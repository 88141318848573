import React, {useEffect, useState} from "react"
import {Dropdown} from "react-bootstrap"

const DropdownList = ({ /*text,*/ options, defaultOption, onChange=f=>f }) => {

  const [selectedChoice, setSelectedChoice] = useState(undefined)

  useEffect(() => {
    if (!selectedChoice && defaultOption){
      const value = options.find((option) => option.value === defaultOption) || {}
      setSelectedChoice(value.label)
    }

    // eslint-disable-next-line
  }, [defaultOption])

  const onChangeValue = ({ label, value }) => {
    setSelectedChoice(label)
    onChange && onChange(value)
  }

  return (
    <Dropdown drop={"down"} style={{ position: "initial" }} size="sm" className="d-inline-block">
      <Dropdown.Toggle size="sm" variant="primary" className="mx-1 my-1" id="dropdown-basic">
        { /*text*/ } <i className="far fa-hand-pointer" /> { selectedChoice }
      </Dropdown.Toggle>
      <Dropdown.Menu>
        { options.map((option, idx) => (
          <Dropdown.Item key={idx} onClick={() => onChangeValue(option)}>{ option.label }</Dropdown.Item>
        )) }
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DropdownList

import React, {useEffect, useState, useMemo} from "react"
import {FormattedMessage} from "react-intl"
import _ from "lodash"
import ReactSelect from "react-select"
import { useFormikContext } from "formik"
import { FieldError, useFieldCSSClasses } from "./FieldError"
import useHideAndShowField from "./hooks/useHideAndShowField"


const SelectFilter = ({
  label,
  placeholder,
  onInputChange,
  options=[],
  multiple=false,
  chainedOptions,
  loadOptions,
  loadValueAttr,
  initialValue="",
  saveOptions,
  required=false,
  field,
  form,
  inputGroupClassName = "form-group",
  ...props
}) => {

  const fieldCSSClasses = useFieldCSSClasses(form.touched, form.errors, field.name)
  const formik = useFormikContext()
  const conditionalShowAndHideClassName = useHideAndShowField({ formik, fieldName: field.name, ...props })
  const [selectedOptions, setSelectedOptions] = useState([])

  const onSelectChange =(option) => {
    let choices = ""
    if (!_.isArray(option) && _.has(option, "value")){
      choices = option.value
    }else if (_.isArray(option)) {
      choices = option.map((choice) => choice.value)
    }
    form.setFieldValue(field.name, choices)
    form.setFieldTouched(field.name, true)
  }

  useEffect(() =>  {
    let choices = []
    if (!_.isArray(field.value) && field.value && _.isArray(selectedOptions)){
      choices.push(selectedOptions.find(option => option.value === field.value))
    }
    if (_.isArray(field.value) && _.isArray(selectedOptions)){
      choices = selectedOptions.filter(option => field.value.includes(option.value))
    }

    console.log(choices, selectedOptions)
    const fieldTouched = _.get(form.touched, field.name, false) || _.isEmpty(selectedOptions)

    if (!_.isEmpty(saveOptions) && _.isPlainObject(saveOptions)){
      form.setFieldValue(
        saveOptions.ref,
        _.flatten(_.map(choices, saveOptions.attr))
      )
    }
    if (!_.isEmpty(saveOptions) && fieldTouched && _.isArray(saveOptions)){
      saveOptions.forEach((field) => {
        let value = _.map(choices, field.attr)
        //if (!_.every(value, _.isEmpty)){
          value = _.flatten(value)
          form.setFieldValue(field.ref, _.isFunction(field.formatter) ? field.formatter(value) : value)
        //}
      })
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value && selectedOptions])

  useEffect(() => {
    if (_.isPlainObject(field.value) && _.has(field.value, "id")){
      form.setFieldValue(field.name, _.get(field.value, "id", ""))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_.isPlainObject(field.value)])

  useEffect(() => {
    const loadValues = _.get(formik.values, loadValueAttr)
    console.log(loadValues)
    if (_.isArray(loadValues) && loadValues.every((item) => _.has(item, "id"))){
      form.setFieldValue(field.name, loadValues.map((item) => _.get(item, "id", "")))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_.get(formik.values, loadValueAttr)])

  useEffect(() => {
    if (_.isFunction(loadOptions) && _.isEmpty(selectedOptions)){
      loadOptions(setSelectedOptions)
    }
    if (options && _.isEmpty(selectedOptions)){
      setSelectedOptions(options)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  useEffect(() => {
    if (_.isFunction(chainedOptions)){
      chainedOptions(setSelectedOptions, formik.values)
    }

    //  eslint-disable-next-line
  }, [formik.values, chainedOptions])

  const selectedValues = useMemo(() => {
    if (!_.isArray(field.value) && _.isArray(selectedOptions)){
      return selectedOptions.find(option => option.value === field.value)
    }
    if (_.isArray(field.value) && _.isArray(selectedOptions)){
      return selectedOptions.filter(option => field.value.includes(option.value))
    }
    return field.value || (multiple ? [] : "")
    //  eslint-disable-next-line
  }, [field.value, selectedOptions])

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FAFAFA",
      border: '.0625rem solid #D6D6D6',
      height: 40
    
    })
  };



  return (
    <div className={inputGroupClassName + conditionalShowAndHideClassName }>
      {label && <label className="custom-label-style"> {label} <span>{(required &&  "*")}</span></label>}
      
      <ReactSelect
      styles={customStyles}
        components={{IndicatorSeparator: () => null}}
        className={fieldCSSClasses}
        placeholder={placeholder}
        options={selectedOptions}
        name={field.name}
        value={selectedValues || ""}
        onChange={onSelectChange}
        onBlur={field.onBlur}
        onInputChange={onInputChange}
        isMulti={multiple}
        noOptionsMessage={() => <FormattedMessage id="GENERAL.SELECT.NO_OPTIONS" />}
        isSearchable
        isClearable
      />
      <FieldError fieldName={field.name} />
    </div>
  )
}


export default SelectFilter

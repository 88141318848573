import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import EditOffTwoToneIcon from "@mui/icons-material/EditOffTwoTone";
import { IconButton, Tooltip } from "@mui/material";
import { FormattedMessage } from "react-intl";
const useStyles = makeStyles((theme) => ({
  label: {
    paddingRight: "0.25rem",
  },
  offIcon: {
    color: "#757575",
    cursor: "not-allowed"
  },
  icon: {
    color: "#1B4883",
  },
  root: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexWrap: "wrap",
  },

  chip: {
    "&.MuiChip-label MuiChip-labelSmall": {
      padding: "0rem",
    },
    margin: theme.spacing(1),
    color: "#1B4883",
    fontSize: "0.625rem",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1rem",
  },
}));

const Chips = ({ label, handleDelete, choices }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        {choices.length > 0 && label && (
          <div className="fontSize-16">{label}</div>
        )}
        {choices.map(
          (choice, idx) =>
            choice.label && (
              <div key={idx} className="d-flex justify-content-between align-items-center">
               <div>
               <Chip
                  classes={{
                    label: classes.label,
                  }}
                  size="small"
                  label={choice.label}
                  className={classes.chip}
                  color={"secondary"}
                />
               </div>
                {/* {choice.isEditable ? ( */}
                  {/* <IconButton onClick={()=>handleDelete(choice.value)}>
                    <Tooltip title={<FormattedMessage id="GENERAL.UPDATE"/>}>
                  <EditTwoToneIcon className={classes.icon} />
                    </Tooltip>
                  </IconButton> */}
                {/* ) : (
                  <IconButton>
                  <Tooltip title={<FormattedMessage id="GENERAL.NO"/>}>
                  <EditOffTwoToneIcon className={classes.offIcon} />
                  </Tooltip>
                  </IconButton>
                )}{" "} */}
              </div>
            )
        )}
           <IconButton onClick={()=>handleDelete()}>
                    <Tooltip title={<FormattedMessage id="GENERAL.UPDATE"/>}>
                  <EditTwoToneIcon className={classes.icon} />
                    </Tooltip>
                  </IconButton>
      </div>
      {/* { choices.length > 0 && <div class="separator separator-dashed my-5"></div> } */}
    </>
  );
};

export default Chips;

import React from 'react'
import { get } from 'lodash'
import { FormattedMessage } from 'react-intl'

import DisplayItem from './DisplayItem'
import { Col } from 'react-bootstrap'
import useHideAndShowField from './hooks/useHideAndShowField'

const Item = ({ field, object }) => {
  const { name, label, html = false, icon = undefined, size = 12 } = field
  const conditionalShowAndHideClassName = useHideAndShowField({ object, ...field })

  return (
    <Col lg={size} className={conditionalShowAndHideClassName}>
      <DisplayItem
        primary={label}
        html={html}
        icon={icon}
        secondary={get(name, object, <FormattedMessage id='GENERAL.NO_DATA' />)}
      />
    </Col>
  )
}

export default Item

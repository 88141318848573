import { useEffect, useState } from "react";
import {throttle} from "lodash"
const useResizeHook = () => { 
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const updateDimensions = throttle(() => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight)
    },200);

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
      }, []);

      return [width,height]
}
export default useResizeHook
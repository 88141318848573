import React, { useEffect } from "react"
import { useFormikContext } from "formik"
import { FieldError, useFieldCSSClasses } from "./FieldError"
import useHideAndShowField from "./hooks/useHideAndShowField"
import { isRLTLang } from "src/i18n"
import { getAttr } from "src/helpers"
import {isFunction} from "lodash"
const Input = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  unit = '',
  inputGroupClassName = "form-group",
  inputClassName = "form-control",
  type = "text",
  required = false,
  disabled = false,
  maxLength = 256,
  form,
  size = 12,
  placeholder,
  loadValue,
  ...props
}) => {

  const unitStyle = isRLTLang() ? {
    position:"absolute",
    height: '1.125rem',
    minWidth: '1.25rem',
    paddingTop: '.375rem',
    left : '1.375rem'
  } : {
    position:"absolute",
    height: '1.125rem',
    minWidth: '1.25rem',
    paddingTop: '.375rem',
    right : '1.375rem'
  }

  const formik = useFormikContext()
  const conditionalShowAndHideClassName = useHideAndShowField({ formik, fieldName: field.name, ...props })
  const fieldCSSClasses = useFieldCSSClasses(touched, errors, field.name)

  useEffect(() => {
    let val ;

    if (isFunction(loadValue)){
      val = loadValue(formik.values)
      form.setFieldValue(field.name,val)
      formik.setFieldValue(field.name,val)
    }


    //  eslint-disable-next-line
  }, [formik.values])
  return (
    <div style={{width:'100%'}} className={ inputGroupClassName + conditionalShowAndHideClassName + ` col-lg-${size}`}>
      {label && <label className="custom-label-style"> {label} <span>{(required &&  "*")}</span></label>}
     {unit && <div style={unitStyle}  >{unit} </div>}
      <input style={{backgroundColor:'#FAFAFA',height:40}}
        type={type}
        disabled={disabled}
        className={`${inputClassName} ${fieldCSSClasses} ${disabled && `disabled`}`}
        maxLength={maxLength}
        autoComplete="off"
        placeholder={placeholder}
        {...field}
        value={field.value || ""}
      />
      <FieldError fieldName={field.name} />
    </div>
  )
}

export default Input

import React, {useEffect, useMemo, useState} from "react"
import { FormattedMessage } from "react-intl"
import {imageExists, toAbsoluteUrl} from "../../../../../../helpers"

import {isValidURL} from "src/helpers"
import Cropper from "../cropper/Cropper"

const iconByFn = {
  cancel: { backgroundImage: `url(${toAbsoluteUrl("/media/svg/icons/General/Scissors.svg")})` },
  remove: { backgroundImage: `url(${toAbsoluteUrl("/media/svg/icons/Files/Deleted-file.svg")})` },
  crop: { backgroundImage: `url(${toAbsoluteUrl("/media/svg/icons/Design/Crop.svg")})` },
  restart: { backgroundImage: `url(${toAbsoluteUrl("/media/svg/icons/General/Update.svg")})` },
}

const iconByFile = {
  "application/(msword|vnd.openxmlformats-officedocument.wordprocessingml.document)": toAbsoluteUrl("/media/svg/files/doc.svg"),
  "application/(gzip|zip)": toAbsoluteUrl("/media/svg/files/zip.svg"),
  "text/css": toAbsoluteUrl("/media/svg/files/css.svg"),
  "application/pdf": toAbsoluteUrl("/media/svg/files/pdf.svg"),
  "application/xml": toAbsoluteUrl("/media/svg/files/xml.svg"),
  "text/javascript": toAbsoluteUrl("/media/svg/files/js.svg"),
  "video/[A-Za-z]": toAbsoluteUrl("/media/svg/files/mp4.svg"),
  "image/[A-Za-z]": toAbsoluteUrl("/media/svg/files/img.svg"),
  "text/csv": toAbsoluteUrl("/media/svg/files/csv.svg"),
}

const defaultIcon = toAbsoluteUrl("/media/svg/files/any.svg")

const Preview = ({ 
  className,
  imageClassName,
  style,
  imageStyle,
  fileWithMeta: { file, cancel, remove, restart },
  meta: { name = '', percent = 0, size = 0, type = undefined, previewUrl, status, duration, validationError },
  isUpload,
  canCancel,
  canRemove,
  saveCroppedImg,
  canRestart,
  extra: { minSizeBytes }
}) => {

  let error = false
  let title = `${name || '?'}`
  if (duration) title = `${title}`

  const [imageExist, setImageExist] = useState(false)
  const [showCropper, setShowCropper] = useState(false)

  const mimeTypeIcon = useMemo(() => {
    const key = Object.keys(iconByFile).find((icon) => (new RegExp(icon)).test(type))
    return iconByFile[key] || defaultIcon
  }, [type])

  useEffect( () => {
    if (!previewUrl && size === 0){
      imageExists(name)
        .then(() => setImageExist(true))
        .catch(() => setImageExist(false))
    }

    // eslint-disable-next-line
  }, [name, previewUrl])

  if (status === 'error_file_size' || status === 'error_validation') {
    return (
      <div className={className} style={style}>
        <span className="dzu-previewFileNameError">{title}</span>
        {status === 'error_file_size' && <span>{size < minSizeBytes ? <FormattedMessage id="GENERAL.TOO_SMALL" /> : <FormattedMessage id="GENERAL.TOO_BIG" />}</span>}
        {status === 'error_validation' && <span>{String(validationError)}</span>}
        {canRemove && <span className="dzu-previewButton" style={iconByFn.remove} onClick={remove} />}
      </div>
    )
  }

  if (status === 'error_upload_params' || status === 'exception_upload' || status === 'error_upload') {
    error = <FormattedMessage id="GENERAL.UPLOAD_FAILED" />
  }
  if (status === 'aborted') {
    error = <FormattedMessage id="GENERAL.UPLOAD_ABORTED" />
  }

  return (

    <div className={className} style={style}>
      {previewUrl && <>
        <img className={imageClassName} style={imageStyle} src={previewUrl} alt={title} title={title} /> 
        <span className={"dzu-previewFileName " + (error ? " text-danger" : "")}>{title} <small>{error}</small></span>
      </>}
      {(!previewUrl && isValidURL(name) && imageExist) && <>
      <img className={imageClassName} style={imageStyle} src={name} alt={title} title={title} /> 
      <span className={"dzu-previewFileName " + (error ? " text-danger" : "")}>{title.substring(title.lastIndexOf('/')+1)} </span>
      </>}
      {(!isValidURL(name) && !previewUrl) && <>
      <img className={imageClassName} style={imageStyle} src={mimeTypeIcon} alt={title} /> 
      <span className={"dzu-previewFileName " + (error ? " text-danger" : "")}>{title} <small>{error}</small></span>
      </>}

      <div className={"dzu-previewStatusContainer "}>
        {isUpload && (
          <progress max={100} value={status === 'done' || status === 'headers_received' ? 100 : percent} />
        )}

        {status === 'uploading' && canCancel && (
          <span className="dzu-previewButton" style={iconByFn.cancel} onClick={cancel} />
        )}
        {status !== 'preparing' && status !== 'getting_upload_params' && status !== 'uploading' && canRemove && (<>
          {/* (new RegExp("image")).test(type) && <span className="dzu-previewButton" style={iconByFn.crop} onClick={() => setShowCropper(true)} /> */}
          <span className="dzu-previewButton" style={iconByFn.remove} onClick={remove} />
          </>)}
        {['error_upload_params', 'exception_upload', 'error_upload', 'aborted', 'ready'].includes(status) &&
            canRestart && <span className="dzu-previewButton" style={iconByFn.restart} onClick={restart} />}
      </div>
      <Cropper show={showCropper} onToggle={() => setShowCropper(false)} image={file} onSave={(blob) => { saveCroppedImg(blob, file);setShowCropper(false)}} />
    </div>
  )
}


export default Preview

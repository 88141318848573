import React, {useMemo} from "react"
import { isEmpty, isArray } from "lodash"
import { useIntl } from "react-intl"
import ContentLoader from "react-content-loader"

import DisplayItem from "./DisplayItem"
import {Col} from "react-bootstrap"
import {getAttr, toAbsoluteUrl} from "../../../../../helpers"
import useHideAndShowField from "./hooks/useHideAndShowField"

const ItemLodaer = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={10}
    viewBox="0 0 100% 10"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width="100%" height="10" />
  </ContentLoader>
)

const Item = ({ field, object }) => {

  const intl = useIntl()

  const { name, formatter = f=>f, label, html=false, icon=undefined, size = 12 } = field

  const conditionalShowAndHideClassName = useHideAndShowField({ object, ...field })

  const value = useMemo(() => {
    return getAttr(object, name, intl.formatMessage({ id: "GENERAL.EMPTY" }))

    // eslint-disable-next-line
  }, [object])

  return (
    <Col lg={size} className={conditionalShowAndHideClassName}>
      <DisplayItem
        primary={ label }
        html={html}
        icon={icon}
        secondary={ !isEmpty(object) ? isArray(value) ? value.map((val) =>
          <img key={val} className="pl-3" height="50" alt="..." src={val ? formatter(val) :  toAbsoluteUrl("/media/svg/files/jpg.svg")} /> ) :
          <img className="" height="50" alt="..." src={value ? formatter(value) :  toAbsoluteUrl("/media/svg/files/jpg.svg")} /> :
          <ItemLodaer />
        }
      />
    </Col>
  )
}


export default Item

export const CREATE_PROGRAM_INIT = "program@CREATE_PROGRAM_INIT";
export const CREATE_PROGRAM_SUCCEEDED = "program@CREATE_PROGRAM_SUCCEEDED";
export const CREATE_PROGRAM_FAILED = "program@CREATE_PROGRAM_FAILED";
export const CLEAR_PROGRAM = "program@CLEAR_PROGRAM";

export const EDIT_PROGRAM_INIT = "program@EDIT_PROGRAM_INIT";
export const EDIT_PROGRAM_SUCCEEDED = "program@EDIT_PROGRAM_SUCCEEDED";
export const EDIT_PROGRAM_FAILED = "program@EDIT_PROGRAM_FAILED";

export const FETCH_PROGRAM_INIT = "program@FETCH_PROGRAM_INIT";
export const FETCH_PROGRAM_SUCCEEDED = "program@FETCH_PROGRAM_SUCCEEDED";
export const FETCH_PROGRAM_FAILED = "program@FETCH_PROGRAM_FAILED";

export const LIST_PROGRAM_INIT = "program@LIST_PROGRAM_INIT";
export const LIST_PROGRAM_SUCCEEDED = "program@LIST_PROGRAM_SUCCEEDED";
export const LIST_PROGRAM_FAILED = "program@LIST_PROGRAM_FAILED";

export const FETCH_MATCH_INIT = "MATCH@FETCH_MATCH_INIT";
export const FETCH_MATCH_SUCCEEDED = "MATCH@FETCH_MATCH_SUCCEEDED";
export const FETCH_MATCH_FAILED = "MATCH@FETCH_MATCH_FAILED";
import { lazy } from "react";
import { combinePathRoutes } from "src/helpers";
import routes from "src/routes";

const student = {
  path: "/student",
  component: lazy(() => import("./../Student")),
  exact: true,
};
const trainingdetails = {
  path: "/training/show",
  component: lazy(() => import("./../Trainingdetails")),
  exact: true,
};

const path = routes.admin.path;

export default combinePathRoutes(
  { path },
  {
    student,
    trainingdetails,
  }
);

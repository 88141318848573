export const SET_FIELDS = "controls@SET_FIELDS"
export const SET_SHOWN_FIELDS = "controls@SET_SHOWN_FIELDS"
export const SET_SUBMITTED = "controls@IS_SUBMITTED"
export const SET_STEPS = "controls@SET_STEPS"
export const SET_OBJECT = "controls@SET_OBJECT"
export const SET_FETCHING = "controls@SET_FETCHING"
export const SET_ERROR = "controls@SET_ERROR"
export const SET_SUCCESS = "controls@SET_SUCCESS"
export const SET_DISPLAY_CONTENT = "controls@SET_DISPLAY_CONTENT"
export const CLEAR_CONTRTOLS = "controls@CLEAR_CONTRTOLS"
export const ADD_SUBMIT_COUNT = "controls@ADD_SUBMIT_COUNT"
export const SET_ERROR_FORM = "controls@SET_ERROR_FORM"

import React from "react"
import { FormattedMessage } from "react-intl"
import { ErrorMessage } from "formik"
import {getAttr} from "../../../../../helpers"

export const FieldError = ({ fieldName }) => {

  const isJSON = (obj) => {
    try {
      JSON.parse(obj);
  } catch (e) {
      return false;
  }
  return true;

  }
  return <ErrorMessage name={fieldName}>
    {(msg) =>
    <div className="invalid-feedback">{isJSON(msg) ? <FormattedMessage { ...JSON.parse(msg) } /> : msg}</div>
    }
  </ErrorMessage>
}

export const useFieldCSSClasses = (touched, errors, fieldName) => {
  const fieldError = getAttr(errors, fieldName, false)
  const fieldTouched = getAttr(touched, fieldName, false)

  const classes = [""]

  if (fieldTouched && fieldError) {
    classes.push("is-INVALID")
  }

  if (fieldTouched && !fieldError) {
    // classes.push("is-valid is-valid-select")
  }

  return classes.join(" ")
}


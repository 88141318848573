import { ACTIONS, ENDPOINTS } from "../constants";
import { CALL_API, HTTP_METHODS } from "../../../../../../constants";

export const clearStore = () => ({
  type: ACTIONS.CLEAR_SESSION,
});

export const fetchSESSION = (param = "") => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_SESSION_INIT,
      success: ACTIONS.FETCH_SESSION_SUCCEDED,
      fail: ACTIONS.FETCH_SESSION_FAILED,
    },
    endpoint: param
      ? ENDPOINTS.SESSION.replace(":param", param)
      : ENDPOINTS.SESSION,
    method: HTTP_METHODS.GET,
    auth: true,
  },
});

export const createSESSION = (payload) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.CREATE_SESSION_INIT,
      success: ACTIONS.CREATE_SESSION_SUCCEDED,
      fail: ACTIONS.CREATE_SESSION_FAILED,
    },
    endpoint: ENDPOINTS.CREATE_SESSION,
    method: HTTP_METHODS.POST,
    auth: true,
    isFormData: true,
  },
});

export const editsession = ({ param }, payload) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.EDIT_SESSION_INIT,
      success: ACTIONS.EDIT_SESSION_SUCCEDED,
      fail: ACTIONS.EDIT_SESSION_SUCCEDED,
    },
    endpoint: ENDPOINTS.EDIT_SESSION.replace(":param", param),
    method: HTTP_METHODS.PATCH,
    auth: true,
    isFormData: true,
  },
});

export const fetchAllSessions = (params) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_ALL_SESSIONS_INIT,
      success: ACTIONS.FETCH_ALL_SESSIONS_SUCCEDED,
      fail: ACTIONS.FETCH_ALL_SESSIONS_FAILED,
    },
    endpoint: ENDPOINTS.FETCH_ALL_SESSIONS,
    method: HTTP_METHODS.GET,
    auth: true,
    params,
  },
});

export const fetchAllSession = () => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_ALL_SESSION_INIT,
      success: ACTIONS.FETCH_ALL_SESSION_SUCCEDED,
      fail: ACTIONS.FETCH_ALL_SESSION_FAILED,
    },
    endpoint: ENDPOINTS.FETCH_ALL_SESSION,
    method: HTTP_METHODS.GET,
    auth: true,
  },
});

import { isPermittedPermission } from "src/helpers"

const useIsDenied = ({ can, isSuperuser,isDssb, permissions }) => {

  if (!isSuperuser && !isDssb && !isPermittedPermission(can, permissions)){
   return true
    }
  return false
}

export default useIsDenied

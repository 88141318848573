import _ from "lodash";
import * as Yup from "yup";
import {
  TIME_PICKER,
  DATE_PICKER,
  INPUT,
  SELECT,
  MULTIPLE_UPLOAD,
  TEXTAREA,
  UPLOAD,
} from "src/components/partials";
import { dateUIHelper } from "src/modules/admin/UIHelpers/time/dateUIHelper";
const sessionTypeOptions = [
  { label: "Select session type here", value: "" },
  { label: "Online", value: "online" },
  { label: "In person", value: "In_person" },
  // { label: "Course", value: "course" },
];
const sessionCategoryOptions = [
  { label: "Select session category here", value: "" },
  { label: "Cours", value: "cours" },
  { label: "Exam", value: "exam" },
];
// Validation schema
export const sessionField = ({ intl }) => [
  {
    name: "sessionName",
    label: intl.formatMessage({ id: "SESSION NAME" }),
    placeholder: intl.formatMessage({ id: "SESSION NAME" }),
    size: 6,
    component: INPUT,
    validation: Yup.string().required(),
    required: true,
  },
  {
    name: "date",
    label: intl.formatMessage({ id: "DATE" }),
    placeholder: intl.formatMessage({ id: "DATE" }),
    size: 6,
    component: DATE_PICKER,
    validation: Yup.string().required(),
    required: true,
    icon: true,
    disabled: true
  },

  {
    name: "time",
    label: intl.formatMessage({ id: "Session Time" }),
    placeholder: intl.formatMessage({ id: "Session Time" }),
    size: 6,
    component: TIME_PICKER,
    validation: Yup.string().required(),
    required: true,
  },

  {
    name: "sessionType",
    label: intl.formatMessage({ id: "Session Type" }),
    placeholder: intl.formatMessage({ id: "session Type" }),
    component: SELECT,
    options: sessionTypeOptions,
    size: 6,
    validation: Yup.string().required(),
    required: true,
  },
  {
    name: "categoryType",
    label: intl.formatMessage({ id: "Category Type" }),
    placeholder: intl.formatMessage({ id: "Category Type" }),
    component: SELECT,
    options: sessionCategoryOptions,
    size: 6,
    validation: Yup.string().required(),
    required: true,
  },
  {
    name: "uploadCours",
    label: intl.formatMessage({ id: "Upload Cours" }),
    placeholder: intl.formatMessage({ id: "Upload Cours" }),
    component: MULTIPLE_UPLOAD,
    size: 8,
    multiple: true,
    validation: Yup.string(),
    // validation: Yup.string().required(),
    // required: true,
  },
  {
    name: "uploadExam",
    label: intl.formatMessage({ id: "Upload Exam" }),
    placeholder: intl.formatMessage({ id: "Upload Exam" }),
    component: MULTIPLE_UPLOAD,
    size: 8,
    multiple: true,
    validation: Yup.string(),
    // validation: Yup.string().required(),
    // required: true,
  },
  {
    name: "description",
    label: intl.formatMessage({ id: "Description" }),
    placeholder: intl.formatMessage({ id: "Description" }),
    component: TEXTAREA,
    size: 6,
    validation: Yup.string().required(),
    // required: true,
  },
];

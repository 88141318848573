import { ACTIONS } from "../constants";

const initialState = {
  sessions: [],
  session: null,
  isFetching: false,
  success: false,
  error: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.CLEAR_SESSION: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
      };
    }

    case ACTIONS.CREATE_SESSION_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.CREATE_SESSION_SUCCEDED: {
      return {
        ...state,
        session: payload,
        success: true,
        error: null,
        isFetching: false,
      };
    }
    case ACTIONS.CREATE_SESSION_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }
    case ACTIONS.FETCH_SESSION_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_SESSION_SUCCEDED: {
      return {
        ...state,
        session: payload,
        success: true,
        error: null,
        isFetching: false,
      };
    }
    case ACTIONS.FETCH_SESSION_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.EDIT_SESSION_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.EDIT_SESSION_SUCCEDED: {
      return {
        ...state,
        session: payload.results,
        success: true,
        error: null,
        isFetching: false,
      };
    }
    case ACTIONS.EDIT_SESSION_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }
    case ACTIONS.FETCH_ALL_SESSIONS_INIT:
      return { ...state, isFetching: true, error: null };

    case ACTIONS.FETCH_ALL_SESSIONS_SUCCEDED:
      return {
        ...state,
        sessions: payload,
        success: true,
        isFetching: false,
        error: null,
      };

    case ACTIONS.FETCH_ALL_SESSIONS_FAILED:
      return { ...state, isFetching: false, error: payload };

    case ACTIONS.FETCH_ALL_SESSION_INIT:
      return { ...state, isFetching: true, error: null };

    case ACTIONS.FETCH_ALL_SESSION_SUCCEDED:
      return {
        ...state,
        sessions: payload.results,
        success: true,
        isFetching: false,
        error: null,
      };

    case ACTIONS.FETCH_ALL_SESSION_FAILED:
      return { ...state, isFetching: false, error: payload };

    default: {
      return state;
    }
  }
};

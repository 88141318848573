import { lazy } from "react";
import { combinePathRoutes } from "src/helpers";
import routes from "src/routes";

const program = {
  path: "/programtraining",
  component: lazy(() => import("../Program")),
  exact: true,
};

const editProgram = {
  path: "/programtraining/:param",
  component: lazy(() => import("../Program")),
  exact: true,
};

const calendar = {
  path: "/programtraining/:param/calendar",
  component: lazy(() => import("../../calander/CalendarPage")),
  exact: true,
};

const sessionForm = {
  path: "/programtraining/:param/session",
  component: lazy(() => import("../../session/components/form/sessionForm")),
  exact: true,
};

const trainingDetails = {
  path: "/programtraining/:param/details",
  component: lazy(() => import("../TrainingDetails")),
  exact: true,
};

const path = routes.admin.path;

const combinedRoutes = combinePathRoutes(
  { path },
  {
    program,
    editProgram,
    calendar,
    sessionForm,
    trainingDetails, // Add the new route to the combined routes
  }
);

export default combinedRoutes;

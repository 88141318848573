import { ACTIONS } from "../constants";

const initialState = {
  programs: [],
  students:[],
  program: null,
  isFetching: false,
  success: false,
  error: null
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    
    case ACTIONS.CLEAR_PROGRAM:
      return { ...state, success: initialState.success, error: null, isFetching: false };

    
    case ACTIONS.CREATE_PROGRAM_INIT:
      return { ...state, isFetching: true, error: null };
    case ACTIONS.CREATE_PROGRAM_SUCCEEDED:
      return { ...state, program: payload, success: true, error: null, isFetching: false };
    case ACTIONS.CREATE_PROGRAM_FAILED:
      return { ...state, isFetching: false, error: payload };

    
    case ACTIONS.EDIT_PROGRAM_INIT:
      return { ...state, isFetching: true, error: null };
    case ACTIONS.EDIT_PROGRAM_SUCCEEDED:
      return { ...state, program: payload, success: true, error: null, isFetching: false };
    case ACTIONS.EDIT_PROGRAM_FAILED:
      return { ...state, isFetching: false, error: payload };

    
    case ACTIONS.FETCH_PROGRAM_INIT:
      return { ...state, isFetching: true, error: null };
    case ACTIONS.FETCH_PROGRAM_SUCCEEDED:
      return { ...state, program: payload, success: true, error: null, isFetching: false };
    case ACTIONS.FETCH_PROGRAM_FAILED:
      return { ...state, isFetching: false, error: payload };

    
    case ACTIONS.LIST_PROGRAM_INIT:
      return { ...state, isFetching: true, error: null };
    case ACTIONS.LIST_PROGRAM_SUCCEEDED:
      return { ...state, programs: payload.results, success: true, error: null, isFetching: false };
    case ACTIONS.LIST_PROGRAM_FAILED:
      return { ...state, isFetching: false, error: payload };



      case ACTIONS.FETCH_MATCH_INIT:
      return { ...state, isFetching: true, error: null };
    case ACTIONS.FETCH_MATCH_SUCCEEDED:
      return { ...state, students: payload, success: true, error: null, isFetching: false };
    case ACTIONS.FETCH_MATCH_FAILED:
      return { ...state, isFetching: false, error: payload };
    
    default:
      return state;
  }
};

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React from "react"
import { Card, CardBody, ModalProgressBar } from "../../controls"
import {ControlUIProvider} from "../store"


const ShowWithoutTitleView = ({ children, isFetching }) => {

  return (
    <ControlUIProvider>
      <Card>
        {isFetching && <ModalProgressBar />}
        <CardBody>
          { children }
        </CardBody>
      </Card>
    </ControlUIProvider>
  )
}


export default ShowWithoutTitleView

import React, {useEffect, useState} from "react"
import {Button, Modal, Col, Row} from "react-bootstrap";
import BaseCropper from "react-cropper"
import { FormattedMessage } from "react-intl"
import { toAbsoluteUrl, toBase64 } from "src/helpers"

import "cropperjs/dist/cropper.css";


const LATEST_CROPPED_IMG = {}

const Cropper = ({ show = false, onToggle, image, onSave }) => {

  const [imageBase64, setImageBase64] = useState()
  const [cropData, setCropData] = useState();
  const [cropper, setCropper] = useState();

  useEffect(() => {
    if (image instanceof File){
      LATEST_CROPPED_IMG[image.lastModified] = LATEST_CROPPED_IMG[image.lastModified] || toAbsoluteUrl("/media/img/cropper.svg")
      toBase64(image).then((img) => setImageBase64(img))
    }
  }, [image])

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      LATEST_CROPPED_IMG[image.lastModified] = cropper.getCroppedCanvas().toDataURL()
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const saveCropData = () => {
    cropper.getCroppedCanvas().toBlob((blob) => {
      onSave(blob)
    })
  }
  
  return (
    <Modal show={show} size="xl" onHide={onToggle} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <FormattedMessage id="GENERAL.CROPPER" />
      </Modal.Header>
      <Modal.Body>
        <BaseCropper
          className="img-cropper"
          zoomTo={0.5}
          initialAspectRatio={16 / 9}
          aspectRatio={16 / 9}
          guides={true}
          autoCrop={true}
          preview=".img-preview"
          src={imageBase64}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={true}
          responsive={true}
          autoCropArea={1}
          checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
        <Row className="mt-4">
          <Col sm="6">
            <h5 className="display-5"><FormattedMessage id="GENERAL.CROPPER_PREVIEW" /></h5>
            <div
              className="img-preview img-thumbnail"
              style={{ width: "100%", height: "15.625rem" }}
            />
          </Col>
          <Col sm="6">
            <h5 className="display-5"><FormattedMessage id="GENERAL.CROPPED_FILE" /> </h5>
            <img className="img-thumbnail cropper-preview" src={cropData || LATEST_CROPPED_IMG[image.lastModified]} alt="cropped" />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={getCropData}>
          <FormattedMessage id="GENERAL.CROP_THE_FILE" />
        </Button>
        <Button
          variant="success"
          onClick={saveCropData}
        >
          <FormattedMessage id="GENERAL.SAVE" />
        </Button>
        <Button variant="danger" onClick={() => onToggle()}>
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>
      </Modal.Footer>
    </Modal>
  );

}



export default Cropper

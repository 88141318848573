import React, { useEffect, useRef } from 'react'
import _ from 'lodash'
import { Button, Col, Row } from 'react-bootstrap'
import { FieldArray } from 'formik'

import { FastField } from "formik"
import { FORM_COMPONENT, DEFAULT_TYPE, SELECT } from "./../types/inputTypes"
import LayoutHeader from '../table/LayoutHeader'
import { useState } from 'react'

const FormRepeater = (props) => {

  // const addRef = useRef()
  const regex = new RegExp('[0-9]')

  const { label, min = 0, field, fields, form, showDeleteButton=true, showAddButton=true, max = 50,disableFirst=0,loadOptions=[] } = props

  const arrayFieldValues = React.useMemo(() => {
    return Array.from(_.get(form.values, field.name, []))

    // eslint-disable-next-line
  }, [form.values, field.name])
    
  const initialSnapshot = React.useMemo(() => {
    const snapshot = {}
    fields.forEach(field => {
      _.set(snapshot, field.name.substring(field.name.lastIndexOf('[]') + 1), _.get(field, 'initialValue', ''))
    })
    return snapshot

    // eslint-disable-next-line
  }, [fields])

  const [options, setOptions] = useState(new Map());

  // Add a key-value pair to the Map
  const addToOptions = (key, value) => {
    setOptions(new Map(options.set(key, value)));
  };


  const loadOptionsValue = async (fieldName,loadFieldOptions) => {
    //if options contain the values for the current fieldName don nothing
    if (options.has(fieldName)){
      return
    }  
    //else load the options from the api and add it to the map
    await loadFieldOptions((value)=>addToOptions(fieldName,value))
  }

  useEffect(()=>{
    if (!_.isEmpty(loadOptions)){
      loadOptions.forEach(({fieldName,loadFieldOptions})=>{
        //forEach field exectue the loadOptionsValue
        loadOptionsValue(fieldName,loadFieldOptions)
      })
    }
  },[])

  const renderField = ({ name, arrayName, component, ...props }) => !name ? (null) : (
    <FastField
      key={arrayName + name}
      name={ arrayName + name }
      component={FORM_COMPONENT[component] || DEFAULT_TYPE }
      { ...props }
    />
  )
  

    
  const renderFields = (arrayName, fields,index) => (<>
    {fields.map((input, i) => {
      const {  validation, ...field } = input
      const {hideOn} = field;
      let field2 

      // if the component is select, not hidden and has to load options from the api 
      if (!_.isEmpty(hideOn) && options.has(field.name) && field.component === SELECT) {
       field2 ={...field,hideOn:`${arrayName}${hideOn}`,options:options.get(field.name)}

      }

      // if the component is select, hidden and has to load options from the api 
      else if (_.isEmpty(hideOn) && options.has(field.name) && field.component === SELECT) {
        field2={...field,options:options.get(field.name)}
      }

     // if the component is not select and not hidden 
      else if (field.component !== SELECT && _.isEmpty(hideOn)) {
        field2={...field}

      }

     // if the component is not select and hidden 
      else if (field.component !== SELECT && !_.isEmpty(hideOn)) {
        field2 ={...field,hideOn:`${arrayName}${hideOn}`}
      }
      
      // if the component is select, not hidden and dosent have to load options from the api
      else if (field.component === SELECT && _.isEmpty(hideOn) && _.isEmpty(loadOptions)) {
        field2={...field}

      }
      // if the component is select, hidden and dosent have to load options from the api 

      else if (field.component === SELECT && !_.isEmpty(hideOn) && _.isEmpty(loadOptions)) {
        field2 ={...field,hideOn:`${arrayName}${hideOn}`}
      }
     
      return disableFirst &&  disableFirst > index ? (
          renderField({
            ...field2,
            disabled:true,
            arrayName
          }) 
      ) : (
        renderField({
          ...field2,
          arrayName
        }) 
    )
    })}
  </>)

  
  return (
    <div className={'p-3'}>
      <FieldArray
       subscription={{}}
        name={field.name}
        render={arrayHelpers => {
          return (
            <div>
          
              {( _.isArray(arrayFieldValues)) && arrayFieldValues.map((__, index) => (
                <div className={index === arrayFieldValues.length - 1 ? 'mt-5' : 'mt-5 border-bottom'} key={index}>
                  <Row >
                    <Col lg={ showDeleteButton ? 11 : 12}>
                      <Row>
                        { renderFields(`${field.name}.${index}.`, fields,index) }
                      </Row>
                    </Col>
                    {showDeleteButton && index >= disableFirst && <Col lg='1' className={ (regex.test(field.name) ? '': 'border-left ') + 'd-flex align-items-center justify-content-center'}>
                      <Button
                        disabled={min > 0 ? _.isArray(arrayFieldValues) && arrayFieldValues.length === min : false}
                        className='btn btn-sm font-weight-bold btn-danger btn-icon'
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <i className='fas fa-trash-alt' />
                      </Button>
                    </Col> }
                  </Row>
                </div>
              ))}
                  { showAddButton && <LayoutHeader 
                  className="p-0"
                disabled={_.isArray(arrayFieldValues) && arrayFieldValues.length >= max}
                // ref={addRef}
                buttonText={label}
                goTo={() => _.isArray(arrayFieldValues) && arrayFieldValues.length < max && arrayHelpers.push(initialSnapshot)}
              />

            }
            </div>
          )
        }}
      />
    </div>
  )
}
export default FormRepeater

import { lazy } from "react";

const AdminLayout = lazy(() => import("./modules/admin/layout/Layout"));
const AuthLayout = lazy(() => import("./modules/authentication/layout/Layout"));
const Logout = lazy(() => import("./modules/authentication/containers/Logout"));

export default {
  admin: {
    path: "/admin",
    component: AdminLayout,
  },
  auth: {
    path: "/auth",
    component: AuthLayout,
  },
  logout: {
    path: "/logout",
    component: Logout,
  },
};

import React, { useState } from 'react'
import { FieldError, useFieldCSSClasses } from './FieldError'
import { useFormikContext } from 'formik'
import useHideAndShowField from './hooks/useHideAndShowField'
import { isRLTLang } from 'src/i18n'
import {debounce} from "lodash"
const Textarea = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  length = null,
  inputGroupClassName = 'form-group',
  inputClassName = 'form-control',
  withFeedbackLabel = true,
  customFeedbackLabel,
  size=12,
  required = false,
  disabled=false,
  ...props
}) => {
  const formik = useFormikContext()
  const conditionalShowAndHideClassName = useHideAndShowField({ formik, fieldName: field.name, ...props })
  
  const fieldCSSClasses = useFieldCSSClasses(touched, errors, field.name)
  const [count, setCount] = useState(0);
  const counter = debounce( e => {
    setCount(e.target.value.length);
  },500)
  const counterStyle = isRLTLang() ? {
    position:"absolute",
    height: '18px',
    minWidth: '20px',
    bottom: '20px',
    left : '22px'
  } : {
    position:"absolute",
    height: '18px',
    minWidth: '20px',
    bottom: "20px",
    right : '22px'
  }
  return (
    <div className={inputGroupClassName + conditionalShowAndHideClassName + ` col-lg-${size}`}>
      {label && <label className="custom-label-style"> {label} <span>{(required &&  "*")}</span></label>}
      { length && <p style={counterStyle}> {`${count}/${length}`} </p>} 
     <textarea
      onInput={counter}
      maxLength={length ? length : undefined}
      style={{backgroundColor:'#FAFAFA',height:112}}
        className={`${inputClassName} ${fieldCSSClasses} ${disabled && `disabled`}`}
        {...field}
        disabled={disabled}
        value={field.value || ''}
      />
      <FieldError fieldName={field.name} />
    </div>
  )
}

export default Textarea

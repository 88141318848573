import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { Form, Formik } from "formik"
import _ from "lodash"
import { isRLTLang } from "./../../../../../i18n"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepConnector from '@material-ui/core/StepConnector'
import StepLabel from "@material-ui/core/StepLabel"
import { Button } from "react-bootstrap"
import { createYupSchema } from "../../../../../helpers"
import { useHistory } from "react-router-dom"

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: `calc(${!isRLTLang() ? '-' : ''}50% + 1.25rem)`,
    right:`calc(${isRLTLang() ? '-' : ''}50% + 1.25rem)`
  },
  active: {
    '& $line': {
      borderColor: '#E70012',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#E70012',
    },
  },
  line: {
    borderColor: '#E70012',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    "& .MuiStepLabel-label.MuiStepLabel-active ": { color: "red", },
    "& .MuiStepLabel-label":{fontSize:'1rem',lineHeight:'1.5rem',fontWeight:'500'},
    "& .MuiStepIcon-text":{fill:'#E70012',fontWeight:'bold'},
    "& .MuiStepIcon-active > .MuiStepIcon-text":{fill: "#ffffff"}
  },
  
  button: {
    marginRight: theme.spacing(1),
  },
  iconContainer: { // define styles for icon container
    transform: 'scale(2)',
  },
  icon: {
    color: "#FFFFFF",
    border: ".0625rem solid #E70012",
    borderRadius: "1.5625rem",
  },
  completed: {
    display: 'inline-block'
  },
  active: {
    fill: "#E70012",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const Wizard = ({ children, initialValues = {}, onSubmit,goBack }) => {

  const steps = React.Children.toArray(children)
  const initialSnapshot = React.useMemo(() => {
    steps.forEach(step => {
      const fields =_.get(step, "props.fields", [])
      fields.forEach(field => {
        if(_.isEmpty(_.get(initialValues, field.name)) && !_.isNumber(_.get(initialValues, field.name))) {
          _.set(initialValues, field.name, _.get(field, "initialValue", ""))
        }
      })
    })
    return initialValues
  }, [steps, initialValues])

  const classes = useStyles()
  const [stepNumber, setStepNumber] = useState(0)
  const [snapshot, setSnapshot] = useState(initialSnapshot)

  const step = steps[stepNumber]
  const totalSteps = steps.length
  const isLastStep = stepNumber === totalSteps - 1
  const history = useHistory();




  const next = values => {
    setSnapshot(values)
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1))
  }

  const previous = values => {
    setSnapshot(values)
    setStepNumber(Math.max(stepNumber - 1, 0))
  }

  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      try{
        await step.props.onSubmit(values, bag,next)
      }
      catch{
        return
      }
     
    }
   
    if (isLastStep) {
      return onSubmit(values, bag)
    } 
    else {
        bag.setTouched({})
        next(values)
    }
  }

  const fieldsValidation = React.useMemo(() =>  {
    return createYupSchema(step.props.fields)
  }, [step])
  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={fieldsValidation}
    >
      {formik => (
        <Form>
          <div className={classes.root}>
            <Stepper alternativeLabel className={classes.root} activeStep={ stepNumber } connector={<QontoConnector />}>
              {steps.map(({ props: {label}}, i) => (
                <Step key={i}  completed={ stepNumber > i}>
                  <StepLabel 
                  classes={{ label:classes.label,iconContainer: classes.iconContainer}} StepIconProps={{
                    classes: {
                      root: classes.icon,
                      completed: classes.completed,
                      active: classes.active,
                      text: classes.text ,
                    }
                  }} > { label } </StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
            </div>
          </div>
          {step}
          <div className="d-flex justify-content-between pt-4">
            <div>
                   {stepNumber > 0 ? (
                <Button
                  color="danger"
                  onClick={() => previous(formik.values)}
                >
                  <FormattedMessage id="GENERAL.BACK" />
                </Button>
              ): _.isFunction(goBack) && <Button
              color="danger"
              onClick={goBack}
            >
              <FormattedMessage id="GENERAL.BACK" />
            </Button>}
            </div>
            <div>
             
              <Button
                disabled={formik.isSubmitting}
                type="submit"
                color="primary"
              >
                {isLastStep ? <FormattedMessage id="GENERAL.SAVE" /> :  <FormattedMessage id="GENERAL.NEXT" /> }
              </Button>
            </div>
          </div>
        </Form>
        
        )}
      
    </Formik>
  )
}


export default Wizard
import React from "react";
import { injectIntl } from "react-intl";
import { sessionField } from "./fields/sessionField";

const SessionDetails = ({ session, intl }) => {
  const fields = sessionField({ intl });

  const renderField = (field, value) => (
    <div key={field.name} className="form-group">
      <label className="form-label">{field.label}</label>
      <div className="form-control-static">{value || "N/A"}</div>
    </div>
  );

  return (
    <>
      <h2 className="session-details-title">Session Details</h2>
      <div className="session-details-form">
        {fields.map((field) => renderField(field, session[field.name]))}
      </div>
    </>
  );
};

export default injectIntl(SessionDetails);

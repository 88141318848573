import React, { useEffect } from "react"
import { useSnackbar } from "notistack"
import { injectIntl } from "react-intl"
import { isEmpty,isNull } from "lodash"
import { useDispatch } from "react-redux"
import {formatErrorMessage} from "../../../../helpers"

const FlashMessages = ({ successMsg = [], error=null, onClose=null, intl , onCloseError=null }) => {


  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isEmpty(error) || !isNull(error)) {
     if (onClose && isNull(onCloseError)){
       dispatch(onClose())
     } 
     else if (onCloseError){
      dispatch(onCloseError())
     }
      enqueueSnackbar(intl.formatMessage({ id: formatErrorMessage(error) }), {
        autoHideDuration: 2000 ,
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        }
      })
    }

    // eslint-disable-next-line
  }, [error])

  useEffect(() => {
    successMsg.forEach((obj) => {
      if (obj.condition && obj.label) {
        enqueueSnackbar(obj.label, {
          autoHideDuration: 2000 ,
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
          }
        })
        if (onClose){
          dispatch(onClose())
        }       }
    })

    // eslint-disable-next-line
  }, [successMsg])

  return <></>
}

export default React.memo(injectIntl(FlashMessages))

import _ from "lodash"
import * as Yup from "yup"
import { /*ASYNC_SELECT,*/ INPUT } from "./../../../../../../../components/partials"
//import addressUIHelper from "../../../../../../common/UIHelpers/addressUIHelper"


// Validation schema
const personalInformationFields = ({ intl }) => [
  {
    name: "firstName",
    component: INPUT,
    label: intl.formatMessage({ id: "USER.INPUT.FIRST_NAME" }),
    placeholder: intl.formatMessage({ id: "USER.INPUT.FIRST_NAME" }),
    validation: Yup.string().min(2).max(200).required(),
    size: 12,
  },
  {
    name: "lastName",
    component: INPUT,
    label: intl.formatMessage({ id: "USER.INPUT.LAST_NAME" }),
    placeholder: intl.formatMessage({ id: "USER.INPUT.LAST_NAME" }),
    validation: Yup.string().min(2).max(200).required(),
    size: 12
  },
  {
    name: "currentPassword",
    inputGroupClassName: "pt-5",
    component: INPUT,
    type: "password",
    label: intl.formatMessage({ id: "USER.INPUT.CURRENT_PASSWORD" }),
    placeholder: intl.formatMessage({ id: "USER.INPUT.CURRENT_PASSWORD" }),
    size: 12,
    validation: Yup.string().min(2).max(200).required(),
  },
]


export default _.memoize(personalInformationFields)

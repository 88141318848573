import React from "react"
import { Menu, styled, alpha} from "@material-ui/core"
import { isRLTLang } from "src/i18n";
const StyledMenu = styled((props) => (
    <Menu
    anchorEl
     getContentAnchorEl={null}
      elevation={0}
      anchorOrigin={{
        vertical: 'center',
        horizontal: isRLTLang() ? 'left' : 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: isRLTLang() ? 'left' : 'right',
      }}
      {...props}
    />  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 4,
      border: '.0625rem solid #C0C0C0',
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? '#1C1C1C' : "#1C1C1C",
      '& .MuiMenu-list': {
        padding: '.25rem 0',
      },
      '& .MuiMenuItem-root': {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '.9375rem',
        lineHeight: '1.25rem',
        '& .MuiSvgIcon-root': {
          left: '20.83%',
          right: '20.83%',
          top: '12.5%',
          bottom: '12.5%',
          color: '#1C1C1C',
          marginRight:isRLTLang() ? 0 : theme.spacing(1.5),
          marginLeft: isRLTLang() ? theme.spacing(1.5) : 0, 
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));
  export default StyledMenu
import { pages as homeRoutes } from "./containers/home/routes";
import { pages as profileRoutes } from "./containers/profile/routes";
import { pages as trainersRoutes } from "./containers/Trainers/routes";
import { pages as calendarRoutes } from "../../components/partials/controls/calendar/routes";
import { pages as programRoutes } from "./containers/program/routes";
import { pages as documentationRoutes } from "./containers/document/routes";
import { pages as studentRoutes } from "./containers/Student/routes";
import { pages as trainingsRoutes } from "./containers/Trainings/routes";
import { pages as sessionRoutes } from "./containers/session/routes";
import { pages as calendarPageRoutes } from "./containers/calander/routes"; // Make sure this path is correct

// Combined routes
export default {
  ...homeRoutes,
  ...profileRoutes,
  ...trainersRoutes,
  ...programRoutes,
  ...documentationRoutes,
  ...calendarRoutes,
  ...studentRoutes,
  ...trainingsRoutes,
  ...sessionRoutes,
  ...calendarPageRoutes,
};

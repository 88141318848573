import React, {useMemo} from "react"
import ReactDOMServer from "react-dom/server";
import _ from "lodash"
import {getAttr, toAbsoluteUrl} from "../../../../helpers";
import {I18nProvider, isRLTLang, MetronicI18nProvider} from "../../../../i18n";
import { NESTED_FIELDS, NESTED_LIST_OF_ITEMS, TABLE_OF_ITEMS} from "../display/item-types/item-types";

const initDocDefinition = ({
  info: {
    title: "pdf",
    author: "Infinity managment",
    subject: "pdf Doc",
    keywords: "pdf, doc, gu",
  },
  footer: (currentPage, pageCount) => [{ color: "#312c63", text: currentPage.toString() + "/" + pageCount, alignment: "center" }],
  images: {
    logo: toAbsoluteUrl("/media/logos/logo-dark-small.png")
  },
  defaultStyle: {
    font: "main"
  },
  styles: {
    header: {
      fontSize: 18,
      bold: true,
      headlineLevel: 1,
      margin: [30, 30],
      color: "#312c63",
      alignment: "center"
    },
  }
})

const formatString = (s) => {
  const ltrChars = "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF",
    rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC",
    rtlDirCheck = new RegExp("^[^"+ltrChars+"]*["+rtlChars+"]");

  if (rtlDirCheck.test(s)){
    return s.split(" ").reverse().join("  ").replace("(", ")").replace(")", "(").replace(/<\/?[^>]+(>|$)/g, "")
  }
  return !_.isString(s) ? s : s.replace(/<\/?[^>]+(>|$)/g, "")
}

const convertJsxToHtml = (elem) => {
  const html = ReactDOMServer.renderToStaticMarkup(<MetronicI18nProvider>
    <I18nProvider>{ elem }</I18nProvider>
  </MetronicI18nProvider>)
  return formatString(html.replace(/<\/?[^>]+(>|$)/g, ""))
}

const useDocDefinition = ({ allFields = [], title, object, intl }) => {

  const getFieldData = (object, field) => {
    const { options, name, formatter, component, fields, columns } = field
    if (component === NESTED_LIST_OF_ITEMS){
      const attrValue = getAttr(object, name, {})
      const nestedList = formatter(attrValue)
      const content = [ columns.map((col) => ({ text: formatString(col.text) || intl.formatMessage({ id: "GENERAL.EMPTY" }), color: "#312c63", bold: true })) ]
      Object.keys(nestedList).forEach((key) => {
        content.push([
          ( formatString(key) || intl.formatMessage({ id: "GENERAL.EMPTY" }) ),
          (nestedList[key] || []).map((value) => ( formatString(value) || intl.formatMessage({ id: "GENERAL.EMPTY" }) )).join(", ")
        ])
      })
      return { content, widths: columns.map(() => "*") }
    }

    if (component === NESTED_FIELDS){
      const attrValue = getAttr(object, name, [])
      return attrValue.map((obj) => fields.map((field) => ({ label: formatString(field.label), content: getFieldData(obj, field)})))
    }

    if (component === TABLE_OF_ITEMS) {
      const attrValue = getAttr(object, name, [])
      let content = [ columns.map((col) => ({ text: formatString(col.text), color: "#312c63", bold: true })) ]
      for (let i=0;i<attrValue.length;i++){
        content.push(columns.map((col) => {
          const value = getAttr(attrValue[i], col.dataField, intl.formatMessage({ id: "GENERAL.EMPTY" }))
          if (_.isFunction(col.formatter)){
            return convertJsxToHtml(col.formatter(value, attrValue[i]))
          }
          if (_.isObjectLike(value)){
            return intl.formatMessage({ id: "GENERAL.EMPTY" })
          }
          return formatString(value)
        }))
      }
      return { content, widths: columns.map(() => "*") }
    }

    const attrValue = getAttr(object, name, intl.formatMessage({ id: "GENERAL.EMPTY" })) || intl.formatMessage({ id: "GENERAL.EMPTY" })
  
    if (_.isArray(options) && !_.isArray(attrValue)) {
      const selectedOption = options.find((option) => option.value === attrValue || undefined)
      return (selectedOption && selectedOption.label) || intl.formatMessage({ id: "GENERAL.EMPTY" })
    }else if ( _.isArray(attrValue) && _.isArray(attrValue)){
      const selectedOptions = (options||[]).filter((option) =>  attrValue.includes(option.value))
      return (_.isArray(selectedOptions)) ? selectedOptions.map((val) => val.label).join(", ") : intl.formatMessage({ id: "GENERAL.EMPTY" })
    }
    return formatString(attrValue)
  }

  const documentDefinition = useMemo(() => {
    const documentFields = {}
    const nestedTables = {}
    const nestedItems = []
    allFields.forEach((field) => {
      if (field.hide){
        const hideOnFieldValue = getAttr(object, field.hideOn)
        if ((_.isArray(field.condition) && !field.condition.includes(hideOnFieldValue)) || (!_.isArray(hideOnFieldValue) && hideOnFieldValue !== field.condition)) {
          return
        }
      }
      const fieldName = _.isString(field.name) ? field.name : JSON.stringify(field.name)
      const fieldValue = getFieldData(object, field)
      const label = formatString(field.label)

      if (_.isString(fieldValue) || _.isNumber(fieldValue)) {
        documentFields[fieldName] = [{ text: label, color: "#312c63", bold: true}, fieldValue, ...(documentFields[fieldName] || [])]
        documentFields[fieldName] = isRLTLang()
          ? documentFields[fieldName].reverse()
          : documentFields[fieldName]
      }else if (_.isArray(fieldValue)){
        fieldValue.forEach((nestedValue) => {
          nestedValue.forEach(({ label, content: value }) => {
            if (_.isString(value) || _.isNumber(value)) {
              let body = [{ text: label || intl.formatMessage({ id: "GENERAL.EMPTY" }), color: "#312c63", bold: true}, value]
              nestedItems.push({
                style: "tableExample",
                color: "#444",
                margin: [0, 30, 0, 0],
                table: {
                  widths: [ "*", "*" ],
                  body: [!isRLTLang() ? body : body.reverse()] ,
                },
                alignment: !isRLTLang() ? "left" : "right",
              })
            }else if (_.isPlainObject(value)){
              nestedItems.push({
                style: "tableExample",
                color: "#444",
                table: {
                  headerRows: 2,
                  widths: value.widths,
                  body: !isRLTLang()
                  ? [ [{ text: label, colSpan: value.widths.length, color: "#312c63", bold: true}], ...value.content]
                  : [ [{ text: label, colSpan: value.widths.length, color: "#312c63", bold: true}], ...value.content.map((col) => col.reverse())]
                },
                alignment: !isRLTLang() ? "left" : "right",
              })
            }
          })
        })
      }
      else if (_.isPlainObject(fieldValue)){
        nestedTables[fieldName] = {
          style: "tableExample",
          color: "#444",
          margin: [0, 30, 0, 30],
          table: {
            widths: fieldValue.widths,
            body: !isRLTLang()
            ? [ [{ text: label, colSpan: fieldValue.widths.length, color: "#312c63", bold: true}], ...fieldValue.content]
            : [ [{ text: label, colSpan: fieldValue.widths.length, color: "#312c63", bold: true}], ...fieldValue.content.map((col) => col.reverse())]
          },
          alignment: !isRLTLang() ? "left" : "right",
        }
      }
    })

    const mainTable = [{
      style: "tableExample",
      color: "#444",
      table: {
        widths: [ "*", "*" ],
        body: Object.values(documentFields)
      },
      alignment: !isRLTLang() ? "left" : "right",
    }]

    return ({
      ...initDocDefinition,
      content: [
        { image: "logo", alignment: !isRLTLang() ? "left" : "right" },
        {
          text: _.isString(title) ? formatString(title) : convertJsxToHtml(title),
          style: "header",
        },
        ...mainTable,
        ...Object.values(nestedTables),
        ...nestedItems,
      ],
    })

    // eslint-disable-next-line
  }, [allFields, object])

  return documentDefinition
}


export default useDocDefinition

import { lazy } from "react";
import { combinePathRoutes } from "src/helpers";
import routes from "src/routes";
const session = {
  path: "/session",
  component: lazy(() => import("../Session1")),
  exact: true,
};

const editsession = {
  path: "/session/:param",
  component: lazy(() => import("../Session1")),
  exact: true,
};
const path = routes.admin.path;

export default combinePathRoutes(
  { path },
  {
    session,
    editsession
  }
);

import React from "react"
import { FormattedMessage } from "react-intl"
import PersonalInformationForm from "./../form/PersonalInformationForm"
import {updatePersonalInformation} from "../../store/actions"
import {useDispatch, useSelector, shallowEqual} from "react-redux"
import { useHistory } from "react-router-dom"

import routes from "./../../../../../../routes"
import {FormView} from "../../../../../../components/partials"

const PersonalInformation = () => {

  const dispatch = useDispatch()
  const onSubmit = (values) => {
    dispatch(updatePersonalInformation(values))
  }

  const { account, isLoading, error } = useSelector(
    (state) => ({
      error: state.admin.profile.error,
      account: state.admin.profile.account,
      isLoading: state.admin.profile.isLoading
    }),
    shallowEqual
  )

  const history = useHistory()

  const goBackTo = () => {
    history.push(routes.admin.path)
  }

  return (
    <FormView
      goBackTo={goBackTo}
      title={<FormattedMessage id="USER.PERSONAL_INFORMATION.TITLE" />}
      isLoading={isLoading}
    >
      { ({ saveRef }) => (<PersonalInformationForm 
        error={error} 
        onSubmit={onSubmit} 
        initialValues={account} 
        saveRef={saveRef}
          />
      ) }
    </FormView>
  )
}

export default PersonalInformation

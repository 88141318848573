import React, { forwardRef, useEffect, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { isRLTLang } from "src/i18n"
import { RECAPCHA_SITE_KEY } from "src/constants"
import { useDispatch } from "react-redux"
import { storeCaptchaRef } from "src/modules/authentication/store/actions"



const InvisibleRecapcha = forwardRef((props,ref )=> {
     
  return (
    <div style={{zIndex:"900"}}>
      <ReCAPTCHA
      id="JKLI"
      hl={isRLTLang() ? "ar" : "fr"}
      sitekey={RECAPCHA_SITE_KEY}
      size="invisible"
      ref={ref}
  />
    </div>
  )
}
)

export default InvisibleRecapcha;

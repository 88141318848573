import React, { useMemo, useState } from "react"
import { useFormikContext } from "formik"
import { FieldError, useFieldCSSClasses } from "./FieldError"
import useHideAndShowField from "./hooks/useHideAndShowField"
import { isRLTLang } from "src/i18n";
import { FormattedError } from "../../alerts/FormattedError";
import _ from "lodash";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SignalCellularNullOutlined } from "@material-ui/icons";
import { getOtpError } from "src/modules/authentication/store/selectors";
import { clearError } from "src/modules/authentication/store/actions";
import { formatMessageError } from "src/helpers";

export const RE_DIGIT = new RegExp(/^\d+$/);

const InputOtp = ({
  field,
  size, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  inputGroupClassName = "form-group",
  type = "text",
  required = false,
  countDown = false,
  disabled = false,
  maxLength = 1,
  selector = false,
  clear = false,
  error,
  setError,
  valueLength,
  placeholder,
  form,
  value,
  timer,
  ...props
}) => {

  const formik = useFormikContext()

  const conditionalShowAndHideClassName = useHideAndShowField({ formik, fieldName: field.name, ...props })
  const fieldCSSClasses = useFieldCSSClasses(touched, errors, field.name)
  const [otp,setOtp] = useState(field.value || "");

  const errorSelector = useSelector(
    getOtpError,
    shallowEqual
  )

 
  React.useEffect(() => {
    form.setFieldValue(field.name, otp)
    // eslint-disable-next-line
  }, [otp])
  const focusToNextInput = (target) => {
    const nextElementSibling =
      target.nextElementSibling ;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  //
  const focusToPrevInput = (target) => {
    const previousElementSibling =
      target.previousElementSibling ;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };
  const valueItems = useMemo(() => {
    const valueArray = otp.split('');
    const items = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [otp, valueLength]);

  //

  const onChange = (otp) => setOtp(otp.trim());

  //
  const inputOnKeyDown = (e) => {
    const target = e.target ;
    const targetValue = target.value;
    const { key } = e;

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    if (e.key !== 'Backspace' || target.value !== '') {
      return;
    }
    target.setSelectionRange(0, targetValue.length);

    const previousElementSibling =
      target.previousElementSibling ;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  //

  const inputOnFocus = (e) => {
    const { target } = e;
    const prevInputEl =
      target.previousElementSibling ;

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  //

  const dispatch = useDispatch();
  const inputOnChange = (
    e,
    idx
  ) => {
    
    if (_.isFunction(setError))
    {
      setError(null)
    }
    if (clear === true && !_.isNull(errorSelector)){
      dispatch(clearError())
    }
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    const nextInputEl = target.nextElementSibling ;

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return;
    }


    targetValue = isTargetValueDigit ? targetValue : ' ';

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        otp.substring(0, idx) + targetValue + otp.substring(idx + 1);

      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      const nextElementSibling =
        target.nextElementSibling ;

      if (nextElementSibling) {
        nextElementSibling.focus();
      }
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);
      form.target.blur();
    }
  };

  const otpStyle={
  width:'30px', 
  height:"40px",
  background: "#F5F5F5",
  textAlign:'center',
  border: "0.5px solid #808080",
  borderRadius: "4px", }

//
  const dir = isRLTLang() ? 'rtl':'ltr';
  return (
    <div style={{width:'100%'}} className={ inputGroupClassName + conditionalShowAndHideClassName + ` col-lg-${size}`}>
      {label && !countDown && <label className="custom-label-style"> {label} <span>{(required &&  "*")}</span></label>}
      {label && countDown && <div className="d-flex justify-content-between">
        <div><label className="custom-label-style"> {label} <span>{(required &&  "*")}</span></label> </div>
          {props.counter}
        </div>}

     <div  dir="ltr" style={{ display:'flex',alignItems:'flex-start',justifyContent:'space-between'}}>
     {valueItems.map((digit, idx) => (
       <input
       onChange={(e) => inputOnChange(e, idx)}
       key={idx}
       placeholder={placeholder}
       type="text"
       inputMode="numeric"
       autoComplete="one-time-code"
       pattern="\d{1}"
       maxLength={maxLength}
       style={otpStyle}
       className={` ${fieldCSSClasses}`}
       onKeyDown={inputOnKeyDown}
       onFocus={inputOnFocus}
        value={digit}
        />
      ))}
      </div>
      { errorSelector && selector ? <FormattedError message={errorSelector.data.detail  === "Authentication failed" ? "AUTH.OTP.ERROR" : errorSelector.data.detail} onClose={null}/>  : <></>}
      { error ? <FormattedError message={error} onClose={null}/>  : <></>}
      <FieldError fieldName={field.name} />
      
    </div>
  )
}


export default InputOtp

import {getAttr} from "../../../../../../helpers"
import { isString, isArray } from "lodash"
import {useMemo} from "react"


const useHideAndShowField = ({ object, hide = false, hideOn, condition = false }) => {

  const show = useMemo(() => {
    if (isString(hideOn) && hide){
      const value = getAttr(object, hideOn, null)
      return isArray(condition) ? !condition.includes(value) : condition !== value
    }
      return false
    // eslint-disable-next-line
  }, [object])

  return show ? " d-none" : " "
}


export default useHideAndShowField

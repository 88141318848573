export const CLEAR_ERROR = "common@CLEAR_ERROR"
export const CLEAR_SUCCESS = "common@CLEAR_SUCCESS"

export const SHOW_SUCCESS = "common@SHOW_SUCCESS"


export const SHOW_NAME_SPACE_MODAL = "common@SHOW_NAME_SPACE_MODAL"
export const HIDE_NAME_SPACE_MDAOL = "common@HIDE_NAME_SPACE_MDAOL"


export const LOGIN_INIT = "authentication@LOGIN_INIT"
export const LOGIN_SUCCEDED = "authentication@LOGIN_SUCCEDED"
export const LOGIN_FAILED = "authentication@LOGIN_FAILED"

export const REGISTER_INIT = "authentication@REGISTER_INIT"
export const REGISTER_SUCCEDED = "authentication@REGISTER_SUCCEDED"
export const REGISTER_FAILED = "authentication@REGISTER_FAILED"

export const FORGOT_PASSWORD_INIT = "authentication@FORGOT_PASSWORD_INIT"
export const FORGOT_PASSWORD_SUCCEDED = "authentication@FORGOT_PASSWORD_SUCCEDED"
export const FORGOT_PASSWORD_FAILED = "authentication@FORGOT_PASSWORD_FAILED"

export const FETCH_TOKEN_INIT = "authentication@FETCH_TOKEN_INIT"
export const FETCH_TOKEN_SUCCEDED = "authentication@FETCH_TOKEN_SUCCEDED"
export const FETCH_TOKEN_FAILED = "authentication@FETCH_TOKEN_FAILED"

export const UPDATE_PERSONAL_INFORMATION_SUCCEDED = "profile@UPDATE_PERSONAL_INFORMATION_SUCCEDED"

export const RESET_PASSWORD_INIT = "authentication@RESET_PASSWORD_INIT"
export const RESET_PASSWORD_SUCCEDED = "authentication@RESET_PASSWORD_SUCCEDED"
export const RESET_PASSWORD_FAILED = "authentication@RESET_PASSWORD_FAILED"

export const LOGOUT_INIT = "authentication@LOGOUT_INIT"
export const LOGOUT_SUCCEDED = "authentication@LOGOUT_SUCCEDED"
export const LOGOUT_FAILED = "authentication@LOGOUT_FAILED"

export const CHANGE_CURRENT_CENTER_INIT = "CENTER@CHANGE_CURRENT_CENTER_INIT"
export const CHANGE_CURRENT_CENTER_SUCCEDED = "CENTER@CHANGE_CURRENT_CENTER_SUCCEDED"
export const CHANGE_CURRENT_CENTER_FAILED = "CENTER@CHANGE_CURRENT_CENTER_FAILED"


export const CHANGE_PHONE_NUMBER_INIT = "structure@CHANGE_PHONE__NUMBER_INIT"
export const CHANGE_PHONE_NUMBER_SUCCEDED = "structure@CHANGE_PHONE__NUMBER_SUCCEDED"
export const CHANGE_PHONE_NUMBER_FAILED = "structure@CHANGE_PHONE__NUMBER_FAILED"

export const CHANGE_PHONE_NUMBER_OTP_INIT = "structure@CHANGE_PHONE__NUMBER_OTP_INIT"
export const CHANGE_PHONE_NUMBER_OTP_SUCCEDED = "structure@CHANGE_PHONE__NUMBER_OTP_SUCCEDED"
export const CHANGE_PHONE_NUMBER_OTP_FAILED = "structure@CHANGE_PHONE__NUMBER_OTP_FAILED"
import { lazy } from "react";
import { VIEW } from "src/constants";
import { DASHBOARD } from "src/constants/AppPermissions";
import { VaccinationManagement } from "src/constants/ModulesPermissions";
import { combinePathRoutes } from "src/helpers";
// import ResetPhoneNumber from "src/modules/authentication/containers/ResetPhoneNumber";
import routes from "src/routes";

const Home = lazy(() => import("./../Home"));
const ResetPassword = lazy(() =>
  import("../../../../authentication/containers/ResetPassword")
);
const ResetPhoneNumber = lazy(() =>
  import("../../../../authentication/containers/ResetPhoneNumber")
);
const ProfiledDisplay = lazy(() => import("../components/ProfileDisplay"));
const Login = lazy(() => import("../Login"));
const AboutUs = lazy(() => import("../AboutUs"));
const ContactUs = lazy(() => import("../ContactUs"));

const home = {
  path: "/home",
  component: Home,
  exact: true,
  can: VaccinationManagement.module[DASHBOARD].permissions[VIEW],
};

const resetPassword = {
  path: "/reset-password",
  component: ResetPassword,
};
const resetPhone = {
  path: "/reset-phone",
  component: ResetPhoneNumber,
};

const profileDisplay = {
  path: "/profile/display",
  component: ProfiledDisplay,
};

const login = {
  path: "/login",
  component: Login,
};

const aboutUs = {
  path: "/about",
  component: AboutUs,
};
const contacteUs = {
  path: "/contact",
  component: ContactUs,
};
const path = routes.admin.path;
export default combinePathRoutes(
  { path },
  {
    home,
    resetPassword,
    resetPhone,
    profileDisplay,
    login,
    aboutUs,
    contacteUs
  }
);

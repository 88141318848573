import { lazy } from "react";
import { combinePathRoutes } from "src/helpers";
import routes from "src/routes";

const trainings = {
  path: "/trainings",
  component: lazy(() => import("./../Trainings")),
  exact: true,
};

const path = routes.admin.path;

export default combinePathRoutes(
  { path },
  {
    trainings,
  }
);

import { SketchPicker } from 'react-color'
import React, { useEffect, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import { FieldError, useFieldCSSClasses } from './FieldError'
import useHideAndShowField from './hooks/useHideAndShowField'

const Input = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  inputGroupClassName = 'form-group',
  inputClassName = 'form-control',
  type = 'text',
  required = false,
  disabled = false,
  maxLength = 256,
  form,
  placeholder,
  ...props
}) => {
  const wrapperRef = useRef()
  const formik = useFormikContext()
  const conditionalShowAndHideClassName = useHideAndShowField({ formik, fieldName: field.name, ...props })
  const fieldCSSClasses = useFieldCSSClasses(touched, errors, field.name)

  const [show, setShow] = useState(false)

  const onComplete = color => {
    form.setFieldValue(field.name, color.hex)
  }

  useEffect(() => {
    document.addEventListener('mousedown', (event) => wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target) && setShow(false))

    return () => document.removeEventListener('mousedown', () => setShow(false))
  }, [])

  return (
    <div ref={wrapperRef} className={inputGroupClassName + conditionalShowAndHideClassName}>
      {label && <label> {label} {(required && ' *')}</label>}
      <div className='input-group' onClick={() => setShow(!show)}>
        <div className='input-group-prepend'>
          <span className='input-group-text input-group-img' style={{ backgroundColor: field.value, border: field.value }} />
        </div>
        <input
          type={type}
          disabled
          className={`${inputClassName} ${fieldCSSClasses}`}
          maxLength={maxLength}
          autoComplete='>off'
          placeholder={placeholder}
          {...field}
          value={field.value || ''}
        />
      </div>
      {show && <SketchPicker color={field.value} onChangeComplete={onComplete} />}
      <FieldError fieldName={field.name} />
    </div>
  )
}

export default Input

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useFormikContext } from "formik";
import Datetime from "react-datetime";
import moment from "moment";
import { FieldError, useFieldCSSClasses } from "./FieldError";
import SVG from "react-inlinesvg";
import "moment/locale/ar-tn";
import "moment/locale/fr";
import { getLang, isRLTLang } from "./../../../../../i18n";
import { FR, AR } from "./../../../../../constants";
import "react-datetime/css/react-datetime.css";
import useHideAndShowField from "./hooks/useHideAndShowField";
import { getAttr, toAbsoluteUrl } from "src/helpers";
import useResizeHook from "./hooks/useResizeHook";

const locale = {
  [FR]: "fr",
  [AR]: "ar-tn",
};
const DatePickerField = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  relatedTo,
  changeDateValue,
  icon = false,
  isValidDate = ()=>true,
  inputGroupClassName = "form-group",
  inputClassName = "form-control-date-picker form-control",
  type = "date",
  format = "DD/MM/YYYY",
  placeholder,
  size = 12,
  dateFormat = true,
  timeFormat = false,
  required = false,
  disabled = false,
  maxLength = 256,
  initialValue = null,
  initialValueChainedField="",
  resetConditionValue=undefined,
  ...props
}) => {
  const { touched, errors } = form;
  const { value } = field;
  const [currentDate, setCurrentDate] = React.useState(undefined);
  const fieldCSSClasses = useFieldCSSClasses(touched, errors, field.name);
  const [toggle, setToggle] = useState(false);
  const iconStyle = isRLTLang()
    ? {
        position: "absolute",
        height: "1.125rem",
        minWidth: "1.25rem",
        top: "calc(100% - 1.875rem)",
        right: "calc(100% - 2.5rem)",
      }
    : {
        position: "absolute",
        height: "1.125rem",
        minWidth: "1.25rem",
        top: "calc(100% - 1.875rem)",
        left: "calc(100% - 2.5rem)",
      };

    
    

  //custom input with calendar
  const Input = (props, openCalendar, closeCalendar) => {
    /*const clear = () => {
      props.onChange({ target: { value: "" } });
    }*/
    const toggleCal = () => {
      if (disabled === false) {
        setToggle((prev) => !prev);
        if (toggle === false) openCalendar();
        else closeCalendar();
      }
    };
    

    
    return (
      <div style={{ width: "100%" }}>

        <SVG
          src={toAbsoluteUrl("/media/svg/dateIcon.svg")}
          style={iconStyle}
          onClick={toggleCal}
          size="3em"
        />{" "}
        <input
          className={`${disabled && `disabled`}`}
          {...props}
          style={{
            width: "100%",
            padding: ".625rem",
            textAlign: "start",
            backgroundColor: "#FAFAFA",
            height: 40,
          }}
        />{" "}
      </div>
    );
  };



  const [width, height] = useResizeHook();
  const [isDisabled,setIsDisabled] = useState(false);

  useEffect(() => {
    adjustDatePickerPosition();
  
    return () => {
      
    }
  }, [height,width])
  
  const formik = useFormikContext();
  const conditionalShowAndHideClassName = useHideAndShowField({
    formik,
    fieldName: field.name,
    ...props,
  });
  React.useEffect(() => {
    let date;
    if (!value) return;
    if (_.isString(value) && !_.isEmpty(value)) {
      date = moment(value, format);
    }
    if (_.isDate(value)) {
      date = moment(value);
    }
    if (date) {
      setCurrentDate(date);
      form.setFieldValue(field.name, moment(date).format(format));
      if (relatedTo && changeDateValue)
      form.setFieldValue(relatedTo,changeDateValue(moment(date)))
    } else {
      setCurrentDate(undefined);
      form.setFieldValue(field.name, initialValue);
    }
    // eslint-disable-next-line
  }, [value, format]);


  useEffect(() => {
    
    if(!_.isEmpty(initialValueChainedField) && resetConditionValue){
     
      if  (getAttr(formik.values,initialValueChainedField,undefined) === resetConditionValue) {
        form.setFieldValue(field.name, "");
        formik.setFieldValue(field.name, "")
        setCurrentDate(undefined)
        setIsDisabled(false);        

      }
      else{
        form.setFieldValue(field.name, initialValue);
        formik.setFieldValue(field.name, initialValue);
        setIsDisabled(true);       
      }
    }
  
    return () => {
      
    }
  }, [getAttr(formik.values,initialValueChainedField)])
  
  const dir = isRLTLang() ? "rtl" : "ltr";
  function adjustDatePickerPosition (){
    let elements = [...document.getElementsByClassName("rdt")];
    if (_.isArray(elements)) {
      elements.forEach((element)=>{
        const elementRect = element.getBoundingClientRect();
        const spaceBelow = window.innerHeight - elementRect.bottom;
        let popElements = [...document.getElementsByClassName("rdtPicker")];  
        if (_.isArray(popElements)){
          popElements.forEach((popElement)=>{
            if (spaceBelow < 250) {
              popElement.classList.add('flip-Position')
            }
            else {
              popElement.classList.remove('flip-Position')
        
            }
          })
        }
      })
    }
  
  }
  return (
    <div
      dir={dir}
      className={ inputGroupClassName + conditionalShowAndHideClassName + ` col-lg-${size}`}    >
      {label && (
        <label className="custom-label-style">
  
          {label} <span>{required && "*"}</span>
        </label>
      )}
      <Datetime
        onOpen={
        ()=> adjustDatePickerPosition()
        }
        popperPlacement="top-start"
        isValidDate={isValidDate}
        renderInput={icon ? Input : null} //remove to remove icon
        key={field.name}
        initialValue={
          currentDate && currentDate.isValid()
            ? currentDate.toDate()
            : undefined
        }
        dateFormat={dateFormat && format}
        timeFormat={false}
        closeOnSelect={true}
        inputProps={{
          key: field.name,
          readOnly: true,
          disabled: disabled || isDisabled,
          autoComplete: "off",
          className: `${inputClassName} ${fieldCSSClasses} ${
            (disabled || isDisabled) && `disabled`
          }`,
          name: field.name,
          value:
            currentDate && currentDate.isValid()
              ? currentDate.format(format)
              : "",
        }}
        locale={locale[getLang()]}
        input={true}
        onChange={(date) => {
          setCurrentDate(date);
          form.setFieldValue(field.name, moment(date).format(format));
        }}
        {...props}
      />{" "}
      <FieldError fieldName={field.name} />{" "}
    </div>
  );
};
export default DatePickerField;

import React from "react"
import { injectIntl } from "react-intl"
import { Formik, Field } from "formik"
import { Button,  } from "react-bootstrap"
import SelectFilter from "../inputs/SelectFilterField"
import {ControlUIProvider} from "../../store"
import {FormattedMessage} from "react-intl"
const SearchFilterSelect = ({ intl, applyFilter,placeholder }) => {
  return (
    <ControlUIProvider>
      <Formik
        onSubmit={(values) => {
          applyFilter(values)
        }}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange
        }) => (
         
          <div className="select-filter">
          <Field
            name="ysearch"
            component={SelectFilter}
            placeholder={placeholder}
            label= {<FormattedMessage id="VACCINE.TYPE"/>}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Field
            name="_search"
            component={SelectFilter}
            placeholder={placeholder}
            label= {<FormattedMessage id="VACCINE.STATUS"/>}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Button className="btn btn-sm btn-secondary">Réinitialiser</Button>
      </div>
       
        )}
      </Formik>
      </ControlUIProvider>
  )
}

export default injectIntl(SearchFilterSelect)

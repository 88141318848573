/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { ModalProgressBar } from "../../controls";

import { Button, Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";

const ActionModal = ({
  success,
  title,
  body,
  onClick,
  onRefresh,
  onHide,
  show,
  isLoading,
  size="md",
}) => {
  const [submitting, setSubmitting] = useState(false);

  // if !id we should close modal
  useEffect(() => {
    if (success && show && submitting) {
      setSubmitting(false);
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, show]);

  const onClickAction = () => {
    setSubmitting(true);
    onClick();
  };

  useEffect(() => {
    if (size == "mapi") {
      let element = document.getElementById("mapi");
      if (element) {
        element.classList.add("modal-mapi");
      }
    }
    return () => {
      if (size == "mapi") {
      let element = document.getElementById("mapi");
      if (element) element.classList.remove("modal-mapi");
      }
    };
  }, [show]);

  return (
    <Modal
      id="mapi"
      size={size}
      centered
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header className="border-0 pb-0">
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        <CloseIcon
          style={{ color: "#979797" }}
          type="button"
          onClick={onHide}
        />
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer className="border-0">
        <div
          className="d-flex flex-row align-items-start"
          style={{ gap: "10px" }}
        >
          <Button
            type="button"
            onClick={onHide}
            className="btn-sm outline-button"
          >
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>
          <Button
            type="button"
            disabled={isLoading}
            onClick={onClickAction}
            className="btn-sm btn-primary btn-elevate"
          >
            {isLoading && <span className="px-5 spinner spinner-white"></span>}
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ActionModal;

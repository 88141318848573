

import { useEffect } from "react"
import { get } from "lodash"
import moment from "moment"
const useResetDateRangePickerField = ({ formik, fieldName,setStartDate,setEndDate,format}) => {
    let startDateValue
    let endDateValue 

    // initilize the values
    useEffect(() => {
      setStartDate(moment(get(formik.values, fieldName,null),format))
      setEndDate(moment(get(formik.values, "endDate",null),format))
      return () => {
      
      }
    }, [get(formik.values, fieldName),get(formik.values, "endDate")])


    // when reset form fired

    useEffect(()=>{
        startDateValue = get(formik.values, fieldName)
        endDateValue = get(formik.values, "endDate")
        if (!startDateValue) {
          setStartDate(null)
        }
        if (!endDateValue) {
          setEndDate(null)
        }
      },[get(formik.values, fieldName),get(formik.values, "endDate")])
  
}


export default useResetDateRangePickerField

import { useCallback, useEffect, useReducer, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { HTTP_METHODS } from './../constants'
import { downloadFile, makeCall } from './../helpers'

const ACTIONS = {
  INIT: 'START_DOWNLODING',
  SUCCESS: 'SUCCESS_DOWNLODING',
  ERROR: 'ERROR_DOWNLODING'
}

const initialState = { isFetching: false, success: false, error: null }

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.INIT: {
      return { ...state, isFetching: true, success: false, error: null }
    }
    case ACTIONS.SUCCESS: {
      return { ...state, isFetching: false, success: true }
    }
    case ACTIONS.ERROR: {
      return { ...state, isFetching: false, error: action.payload }
    }
    default: {
      return state
    }
  }
}

const useDownloader = ({ endpoint, mineType = 'text/csv;charset=utf-8;', params = {}, filename, payload = {}, headers = {}, method = HTTP_METHODS.GET }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [enpointt,setEndpoint] = useState(endpoint)
  const { token } = useSelector(
    (state) => ({
      token: state.common.auth.token
    }),
    shallowEqual
  )

  const downloadTrigger = useCallback((params) => {
    dispatch({ type: ACTIONS.INIT })
    if (params) {
      setEndpoint(endpoint.replace(':param',params))
    }
  },[endpoint])

  useEffect(() => {
    if (state.isFetching) {
      makeCall(method, enpointt, payload, { Authorization: `Bearer ${token}` ,...headers}, params, {
        responseType: 'blob'
      })
        .then(resp => {
          dispatch({ type: ACTIONS.SUCCESS })
          try {
            const fileName = (resp.headers['content-disposition'].split('filename=')[1] || "").replace(/['"]+/g, '')
            const contentType = resp.headers['content-type']
            downloadFile(resp.data, fileName, contentType)
            console.log(fileName, contentType)
          } catch {
            downloadFile(resp.data, filename, mineType)
          }
        })
        .catch(err => {
          dispatch({ type: ACTIONS.ERROR, payload: { err } })
        })
    }

    // eslint-disable-next-line
  }, [state])

  return [state.isFetching, downloadTrigger]
}

export default useDownloader

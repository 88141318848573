import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useFormikContext } from "formik";
import { FormattedMessage } from "react-intl";
import Dropzone from "react-dropzone-uploader";
import { FieldError, useFieldCSSClasses } from "./FieldError";
import Preview from "./dropzone/Preview";
import useHideAndShowField from "./hooks/useHideAndShowField";
import uuid from "uuid";
import { convertUrlToImagesData, toAbsoluteUrl } from "src/helpers";
import withCropper from "./dropzone/PreviewHOC";
import SVG from "react-inlinesvg";
import { FormattedError } from "../../alerts/FormattedError";
import { isRLTLang } from "src/i18n";
const buttonStyle = {
  display: "flex",
  color: "white",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: ".5rem .625rem",
  elevation: "0",
  outline: "none",
  gap: ".5rem",
  width: "7.625rem",
  height: "2.5rem",
  background: "#1B4883",
  borderRadius: isRLTLang()
    ? ".25rem 0rem 0rem .25rem"
    : "0rem .25rem .25rem 0rem",
};
let key = null;
const Uploader = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  maxFiles = 10,
  multiple = false,
  accept,
  formatter = (value) => value.substring(value.lastIndexOf("/") + 1),
  required = false,
  autoUpload = true,
  size = 12,
  disabled = false,
  ...props
}) => {
  const formik = useFormikContext();
  const [editable, setEditable] = useState(false);
  const fieldCSSClasses = useFieldCSSClasses(
    form.touched,
    form.errors,
    field.name
  );
  const conditionalShowAndHideClassName = useHideAndShowField({
    formik,
    fieldName: field.name,
    ...props,
  });

  const [initFiles, setInitFiles] = useState([]);

  useEffect(() => {
    if (
      _.isArray(field.value) &&
      !editable &&
      !_.isEmpty(field.value) &&
      field.value.every((val) => val instanceof File)
    ) {
      setEditable(true);
      setInitFiles([...field.value]);
      key = uuid();
    } else if (
      _.isArray(field.value) &&
      !_.isEmpty(field.value) &&
      field.value.every((val) => _.isString(val))
    ) {
      convertUrlToImagesData(field.value)
        .then((files) => {
          form.setFieldValue(field.name, files);
          setInitFiles(files);
          setEditable(true);
          key = uuid();
        })
        .catch(() => {
          setInitFiles([]);
        });
    } else if (
      _.isArray(field.value) &&
      !editable &&
      !_.isEmpty(field.value) &&
      field.value.some((val) => _.isString(val))
    ) {
      setEditable(true);
      convertUrlToImagesData(field.value.filter((val) => _.isString(val)))
        .then((files) => {
          form.setFieldValue(field.name, [
            ...field.value.filter((val) => val instanceof File),
            ...files,
          ]);
          setInitFiles([
            ...field.value.filter((val) => val instanceof File),
            ...files,
          ]);
          key = uuid();
        })
        .catch(() => {
          setInitFiles([]);
        });
    }

    // eslint-disable-next-line
  }, [field.value]);

  useEffect(() => {
    if (_.isEmpty(field.value) && !editable) {
      setEditable(false);
      key = uuid();
    }

    // eslint-disable-next-line
  }, [formik.submitCount]);

  const handleChangeStatus = ({ file, meta }, status) => {
    if (status === "done" && meta.size > 0) {
      setEditable(true);
      form.setFieldValue(
        field.name,
        _.uniqBy([...(field.value || []), file], "name")
      );
    }
    if (status === "done" && meta.size === 0) {
      //form.setFieldValue(field.name, undefined)
    }
    if (status === "removed") {
      form.setFieldValue(
        field.name,
        _.uniqBy(
          field.value.filter(
            (img) => meta.name !== (_.isString(img) ? formatter(img) : img.name)
          ),
          "name"
        )
      );
    }
  };

  const onCropImg = (croppedBlob, file) => {
    form.setFieldValue(
      field.name,
      field.value.map((elem) =>
        file.name === elem.name
          ? new File([croppedBlob], file.name, {
              lastModified: new Date().getTime(),
              type: croppedBlob.type,
            })
          : elem
      )
    );
  };

  return (
    <div
      className={`form-group ${fieldCSSClasses} ${conditionalShowAndHideClassName} col-lg-${size}`}
    >
      {label && (
        <label className="custom-label-style">
          {" "}
          {label} <span>{required && "*"}</span>
        </label>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: "0rem",
        }}
      >
        <Dropzone
          key={key}
          onChangeStatus={handleChangeStatus}
          maxFiles={maxFiles}
          multiple={true}
          initialFiles={initFiles}
          PreviewComponent={withCropper(Preview, onCropImg)}
          inputContent={
            <FormattedMessage key={1} id="GENERAL.UPLOAD_FILE_CONTENT" />
          }
          inputWithFilesContent={
            <FormattedMessage key={1} id="GENERAL.UPLOAD_FILE_INPUT" />
          }
          autoUpload={autoUpload}
        />
        <button
          disabled={disabled}
          type="button"
          className="btn"
          style={buttonStyle}
          onClick={() => {
            document.getElementsByClassName("dzu-inputLabel")[0].click();
          }}
        >
          <SVG src={toAbsoluteUrl("/media/svg/attach.svg")} size="3em" />
          <div className="fontSize-12">
            <FormattedMessage id="Upload" />
          </div>
        </button>
      </div>
      {/* { error ? <FormattedError message={error} onClose={null}/>  : <></>} */}
    </div>
  );
};

export default Uploader;

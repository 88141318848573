import { getEnv } from "../helpers/EnvHelpers";
import axios from "axios";

export const API_BASE_URL = getEnv("REACT_APP_API_BASE_URL");
export const API_BACK_OFFICE_URL = getEnv("REACT_APP_BACK_OFFICE_API_BASE_URL");
export const API_EXTERNAL_URL = getEnv("REACT_APP_API_EXTERNAL_URL");
export const RECAPCHA_SITE_KEY = getEnv("REACT_APP_RECAPCHA_SITE_KEY");

export const CALL_API = "middleware@CALL_API";

export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

const getHeaders = () => {
  return {
    "Content-Type": "application/json",
    // Add authorization headers if needed
    // 'Authorization': `Bearer ${yourToken}`
  };
};

export const saveSessionToDatabase = async (sessionData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/sessions/`, {
      method: HTTP_METHODS.POST,
      headers: getHeaders(),
      body: JSON.stringify(sessionData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || "Failed to save session");
    }

    return await response.json();
  } catch (error) {
    console.error("Error saving session:", error);
    throw error;
  }
};

export const fetchSessionsFromDatabase = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/sessions/`, {
      method: HTTP_METHODS.GET,
      headers: getHeaders(),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || "Failed to fetch sessions");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching sessions:", error);
    throw error;
  }
};
export const createSession = async (sessionData) => {
  const response = await axios.post(`${API_BASE_URL}/sessions/`, sessionData);
  return response.data;
};

export const getSessions = async () => {
  const response = await axios.get(`${API_BASE_URL}/sessions/`);
  return response.data;
};

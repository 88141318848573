import { lazy } from "react";
import { combinePathRoutes } from "src/helpers";
import routes from "src/routes";

const trainers = {
  path: "/trainers",
  component: lazy(() => import("./../Trainer")),
  exact: true,
};
// const training = {
//   path: "/traininglist",
//   component: lazy(() => import("./../programlist")),
//   exact: true,
// };
const path = routes.admin.path;

export default combinePathRoutes(
  { path },
  {
    trainers,
    // training,
  }
);
